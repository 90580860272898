import {NavLink, useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth-layout";
import Form from "react-bootstrap/Form";
import {login} from "../../helpers/auth";
import * as React from "react";
import UserRepository from "../../../repositories/UserRepository";
import {toastMessage} from "../../helpers/toast";
import {useTranslation} from "react-i18next";
import {Alternative} from "../../components/Login/Alternative";
import {Header} from "../../components/Login/Header";
import {Button} from "../../components/Form/Button";

function SignIn() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        phoneNumber: "",
        password: "",
    });
    const {t} = useTranslation();

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };
    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        try {
            const {data} = await UserRepository.login({
                email: form.phoneNumber,
                phoneNumber: '0677378004',
                password: form.password,
            })
            login(data.token)
            navigate("/home", {replace: true})
        } catch (error) {
            setIsLoading(false);
            toastMessage(error.response.data.message, 'error')
        }
    };
    return <>
        <AuthLayout>
            <Header title="sign_in.title" />
            <NavLink to="/">
                <img src="/img/logo.svg" alt="" className="d-md-none mb-3 mt-2"/>
            </NavLink>
            <p className="text-gray mb-4">{t('sign_in.sub_title')}</p>
            <Form className="text-start" onSubmit={handleSubmit}>
                <div className="d-flex gap-3">
                    {/*<Form.Group className="mb-4">*/}
                    {/*  <Form.Label className="fw-bold">Prefix</Form.Label>*/}
                    {/*  <Form.Select className="w-100px">*/}
                    {/*    <option>+01</option>*/}
                    {/*    <option>+01</option>*/}
                    {/*    <option>+32</option>*/}
                    {/*    <option>+01</option>*/}
                    {/*    <option>+01</option>*/}
                    {/*  </Form.Select>*/}
                    {/*</Form.Group>*/}
                    <Form.Group className="flex-grow-1 mb-4" controlId="phoneNumber">
                        <Form.Label className="fw-bold">{t('form.phone.label')}</Form.Label>
                        <div className="input-icon">
                            <img
                                src="/img/icon_phone_gray.svg"
                                alt=""
                                className="icon-left"
                            />
                            <Form.Control
                                type="email"
                                placeholder={t('form.phone.placeholder')}
                                className="icon-single"
                                value={form.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </Form.Group>
                </div>
                {/*<Form.Group className="mb-4" controlId="email">*/}
                {/*  <Form.Label className="fw-bold">Email</Form.Label>*/}
                {/*  <div className="input-icon">*/}
                {/*    <img src="/img/icon_envelope_gray.svg" alt="" className="icon-left" />*/}
                {/*    <Form.Control*/}
                {/*        type="email"*/}
                {/*        value={form.email}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="Input Email"*/}
                {/*        className="icon-single"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</Form.Group>*/}
                <Form.Group className="mb-4" controlId="password">
                    <Form.Label className="fw-bold">{t('form.password.label')}</Form.Label>
                    <div className="input-icon">
                        <img src="/img/icon_password.svg" alt="" className="icon-left"/>
                        <Form.Control
                            type="password"
                            value={form.password}
                            onChange={handleChange}
                            placeholder={t('form.password.placeholder')}
                            className="icon-single"
                        />
                        <img src="/img/icon_eye.svg" alt="" className="icon-right"/>
                    </div>
                </Form.Group>
                <div className="row">
                    <div className="col-6">
                        <Form.Group className="mb-4" controlId="remember">
                            <Form.Check
                                type="checkbox"
                                label={t('sign_in.remember')}
                                className="text-gray"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-6 text-end">
                        <NavLink to="/forget-pass" className="text-body">
                            {t('sign_in.forgot_password')}
                        </NavLink>
                    </div>
                </div>
                <Button
                    title="sign_in.submit"
                    isLoading={isLoading} />
            </Form>
            <Alternative/>
            <p className="text-gray mt-4 mb-0 text-center">
                {t('sign_in.has_not_account')} <NavLink
                to="/signup"
                className="fw-bold">
                {t('sign_in.title')}
            </NavLink>
            </p>
        </AuthLayout>
    </>
}

export default SignIn;
