import React from "react";
import Header from "./page-header";
import {UserProvider} from "./context/UserProvider";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function PageLayout({children}) {
    return <UserProvider>
        <Header/>
        <ToastContainer/>
        <main className="py-4">{children}</main>
    </UserProvider>
}

export default PageLayout;
