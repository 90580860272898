import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import ForgetPassword from "./components/pages/auth/forget-password";
import RegisterSuccess from "./components/pages/auth/register-success";
import ResetCode from "./components/pages/auth/reset-code";
import ResetPassword from "./components/pages/auth/reset-password";
import SignIn from "./components/pages/auth/sign-in";
import SignUp from "./components/pages/auth/sign-up";
import JoinWithoutAccount from "./components/pages/auth/join-without-account";
import Main from "./components/pages/main/main";
import Expenses from "./components/pages/main/expense";
import Messages from "./components/pages/main/messages";
import ExpensesDetail from "./components/pages/main/expense-detail";
import EventDetail from "./components/pages/main/event-detail";
import EventCreate from "./components/pages/main/event-create";
import EventUpdate from "./components/pages/main/event-update";
import Chat from "./components/pages/main/chat";
import './i18n'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} exact />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/success" element={<RegisterSuccess />} />
      <Route path="/forget-pass" element={<ForgetPassword />} />
      <Route path="/reset-pass" element={<ResetPassword />} />
      <Route path="/reset-code" element={<ResetCode />} />
      <Route path="/e/:id" element={<JoinWithoutAccount />} />
      <Route path="/join-without-account" element={<JoinWithoutAccount />} />
      <Route path="/home" element={<Main />} />
      <Route path="/expenses" element={<Expenses />} />
      <Route path="/expenses-detail" element={<ExpensesDetail />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/chat/:id" element={<Chat />} />
      <Route path="/event-detail/:id" element={<EventDetail />} />
      <Route path="/event-create" element={<EventCreate />} />
      <Route path="/event-update/:id" element={<EventUpdate />} />
    </Routes>
  </BrowserRouter>
);
