import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {EventMessage} from "../../components/EventMessage";
import {Chat as ChatComponent} from "../../components/Chat/Chat";
import {useNavigate} from "react-router-dom";
import EventRepository from "../../../repositories/EventRepository";
import {useTranslation} from "react-i18next";

function Messages() {
    const {t} = useTranslation();
    const [events, setEvents] = useState([]);
    const [eventIdSelected, setEventIdSelected] = useState(0);
    const navigate = useNavigate();
    const width = window.innerWidth;
    const isMobile = width <= 991

    const handlerOpenMessage = async (eventId) => {
        if (isMobile) {
            navigate(`/chat/${eventId}`, {replace: true})
        }
    }

    const getEvents = async () => {
        const {data} = await EventRepository.getAll(['message'])
        setEvents(data)

        setEventIdSelected(data[0]?.id ?? 0)
    }

    useEffect(() => {
        getEvents()
    }, []);

    return <PageLayout>
        <Container>
            <div className="row">
                <div className="col-lg-5">
                    <div className="box-top px-4 px-md-0 bg-white">
                        <div className="box p-md-3">
                            <h1 className="page-title fw-bold">{t('message.title')}</h1>
                            <Form className="title-form mt-4 mt-md-0">
                                <Form.Group>
                                    <div className="input-icon">
                                        <img
                                            src="/img/icon_search.svg"
                                            alt=""
                                            className="icon-left"
                                        />
                                        <Form.Control
                                            type="text"
                                            placeholder={t('message.search')}
                                            className="icon-single"
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                            <div className="msg-listing">
                                {events.length === 0 &&
                                    <div className="text-center">{t('message.no_message')}</div>
                                }
                                {events.map(event =>
                                    <EventMessage
                                        key={event.id}
                                        event={event}
                                        onClick={() => handlerOpenMessage(event.id)}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!isMobile &&
                    <div className="col-lg-7 d-lg-block d-none">
                        <ChatComponent
                            eventId={eventIdSelected}
                        />
                    </div>
                }
            </div>
        </Container>
    </PageLayout>
}

export default Messages;
