import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React from "react";

export function CreateExpenseModal({show, handleClose}) {
    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
        scrollable
    >
        <Modal.Header className="pb-0">
            <Button variant="btn-link btn-sm" onClick={handleClose}>
                Cancel
            </Button>
            <Modal.Title className="h6">New Expense</Modal.Title>
            <Button variant="btn-link btn-sm" className="text-primary">
                Save
            </Button>
        </Modal.Header>
        <Modal.Body>
            <Form className="text-start">
                <Form.Group className="flex-grow-1 mb-3" controlId="title">
                    <Form.Control type="text" placeholder="Title" />
                </Form.Group>
                <Form.Group className="flex-grow-1 mb-3" controlId="amount">
                    <div className="currency-input">
                        <Form.Control type="text" placeholder="Amount" />
                        <span>EUR</span>
                    </div>
                </Form.Group>
                <Form.Group className="flex-grow-1 mb-4" controlId="date">
                    <div className="input-icon">
                        <Form.Control type="date" placeholder="Date" />
                    </div>
                </Form.Group>
                <p className="mb-0">Paid by</p>
                <h6 className="mb-4 d-flex justify-content-between">
                    <strong>Dany</strong>
                    <img src="img/icon_chevron_down.svg" alt="" />
                </h6>
                <div className="theme-checkbox">
                    <Form.Check
                        type="checkbox"
                        label="To"
                        className="d-flex justify-content-between flex-row-reverse"
                    />
                    <Form.Check
                        type="checkbox"
                        label="Dany"
                        className="fw-bold d-flex justify-content-between flex-row-reverse"
                    />
                    <Form.Check
                        type="checkbox"
                        label="Malik"
                        className="fw-bold d-flex justify-content-between flex-row-reverse"
                    />
                    <Form.Check
                        type="checkbox"
                        label="Yoann"
                        className="fw-bold d-flex justify-content-between flex-row-reverse"
                    />
                </div>
            </Form>
        </Modal.Body>
    </Modal>
}