import Form from "react-bootstrap/Form";
import {Header} from "./Header";
import {useTranslation} from "react-i18next";

export function Language({onClose}) {
    const { i18n, t } = useTranslation();
    const languages = Object.keys(i18n.options.resources)

    return <div className="user-profile my-4">
        <Header title='language.title' onClose={onClose} />
        <Form className="text-start mt-4 pt-2">
            {languages.map(language => (
                <Form.Check
                    key={language}
                    type="radio"
                    label={t(`language.${language}`)}
                    name="lang"
                    checked={i18n.language === language}
                    className="d-flex justify-content-between flex-row-reverse p-0 my-2 py-2"
                    onClick={() => i18n.changeLanguage(language)}
                />
            ))}
        </Form>
    </div>
}