import Offcanvas from "react-bootstrap/Offcanvas";
import {useEffect, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import NotificationRepository from "../../../repositories/NotificationRepository";
import {useTranslation} from "react-i18next";

export function Notifications({show, handleClose}) {
    const {t} = useTranslation();
    const [notifications, setNotifications] = useState([]);

    const getNotifications = async () => {
        const {data} = await NotificationRepository.getAll()

        const notifications = data.reduce((acc, notification) => {
            const date = moment(notification.date).format('YYYY-MM-DD');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(notification);

            return acc;
        }, {})

        setNotifications(Object.entries(notifications).map(([date, list]) => {
            return {date, list};
        }));
    }

    const readNotification = async (notification) => {
        await NotificationRepository.read(notification.id)
    }

    useEffect(() => {
        if (show) {
            getNotifications();
        }
    }, [show])


    return <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t('notification.title')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className="notifcation">
                {notifications.map((notification) => <>
                    <div className="day" key={notification.date}>
                        {notification.date}
                        <img
                            src="/img/icon_ellipsis.svg"
                            alt=""
                            className="cursor-pointer"
                        />
                    </div>
                    {notification.list.map((item) => <div
                            className="d-flex gap-3 justify-content-between notify"
                            key={item.id}>
                            <Link
                                to={item.link}
                                className="align-items-center d-flex gap-2"
                            >
                                <div className="img-outer img-md">
                                    <img src="/img/event_img.png" alt=""/>
                                </div>
                                <div className="notify-content">
                                    <h6>{item.title}</h6>
                                    {/*<p className="mb-0 text-gray">*/}
                                    {/*    <span className="text-body">Ruby</span> wants to join the*/}
                                    {/*    event*/}
                                    {/*</p>*/}
                                    <p className="mb-0 text-gray">
                                        {item.content}
                                    </p>
                                </div>
                            </Link>
                            <div className="d-flex flex-column gap-2 notify-action">
                                <img src="/img/icon_tick_circle.svg" alt="" onClick={() => readNotification(item)}/>
                                <img src="/img/icon_cross_circle.svg" alt=""/>
                            </div>
                        </div>
                    )}
                </>)}
            </div>
        </Offcanvas.Body>
    </Offcanvas>
}