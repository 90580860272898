import {Avatar} from "../User/Avatar";
import React from "react";
import {Checklist} from "../Widget/Content/Checklist";
import {Poll} from "../Widget/Content/Poll";
// import {ReactionPicker} from "./ReactionPicker";

export function BullMessage({message, event, type}) {
    const author = message.author ?? message.creator
    const isAuthor = author?.id === event.userCreator.id;

    return <>
        <div className={`align-items-start d-flex gap-2 chat-outer${isAuthor ? ' right' : ''}`}>
            {!isAuthor &&
                <Avatar user={author}/>
            }
            <div className="chatbox-inner">
                {(() => {
                    switch (type) {
                        case 'checklist':
                            return <Checklist
                                checklist={message}
                                callback={() => {}}
                                withBackground={false}
                            />;
                        case 'poll':
                            return <Poll
                                poll={message}
                                callback={() => {}}
                            />;
                        default:
                            return message.content;
                    }
                })()}
            </div>
            {/*<ReactionPicker position={isAuthor ? 'left' : 'right'} />*/}
            {/*<div className="chatbox-reaction">😍 1</div>*/}
        </div>
    </>
}