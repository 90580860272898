import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import EventRepository from "../../../repositories/EventRepository";
import {useTranslation} from "react-i18next";

export function DeleteEventModal({show, handleClose, event}) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleDeleteEvent = async () => {
        try {
            await EventRepository.delete(event.id)
            navigate("/home", {replace: true})
        } catch (err) {
            console.log(err);
        }
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        scrollable
        centered
    >
        <Modal.Body>
            <p className="text-center mt-3 mb-4">{t('modal.delete_event.title', {name: event.label})}</p>
            <div className="row">
                <div className="col-md-6">
                    <Button
                        variant="primary"
                        className="text-white w-100 px-3"
                        onClick={handleClose}
                    >{t('modal.delete_event.cancel')}</Button>
                </div>
                <div className="col-md-6">
                    <Button
                        variant="outline-secondary"
                        className="m-lg-0 mt-2 w-100 px-3"
                        onClick={handleDeleteEvent}
                    >{t('modal.delete_event.submit')}</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}