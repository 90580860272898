import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Header} from "./Header";
import {useState} from "react";
import {Avatar} from "../User/Avatar";

export function PeopleListModal({show, handleClose, event, handleInvite}) {
    const [searchValue, setSearchValue] = useState("")

    const isCreator = (people) => {
        return people.role === 'creator'
    }

    const formatStatus = (people) => {
        if (isCreator(people)) {
            return 'Admin';
        }

        const status = people.status;
        return status.charAt(0).toUpperCase() + status.toLowerCase().slice(1);
    }

    const getClass = (people) => {
        switch (true) {
            case isCreator(people):
                return ''
            case people.status === 'ACCEPTED':
                return 'text-success';
            case people.status === 'PENDING':
                return 'text-warning';
            case people.status === 'REJECTED':
                return 'text-danger';
        }
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal with-footer"
        scrollable
    >
        <Header
            title="Invited people"
            subtitle={`${event?.userEvents?.length} peoples`}
            handleClose={handleClose}
        />
        <Modal.Body>
            <Form className="text-start">
                <Form.Group className="flex-grow-1 mb-3" controlId="title">
                    <Form.Control
                        type="text"
                        placeholder="Search people"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </Form.Group>
                {event?.userEvents?.filter((user) =>
                    user.author.name.match(new RegExp(searchValue, "i"))
                ).map(people => (
                    <div className="align-items-center justify-content-between d-flex gap-2 invite-user" key={people.id}>
                        <div className="align-items-center d-flex gap-2">
                            <Avatar user={people.author} />
                            <div className="user-name">{people.author.name}</div>
                        </div>
                        <div className={`${getClass(people)} user-status`}>
                            {formatStatus(people)} <img src="img/icon_chevron_down.svg" alt=""/>
                        </div>
                    </div>
                ))}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={handleInvite}
                variant="primary btn-sm"
                className="w-100 text-white"
            >
                Invite more
            </Button>
        </Modal.Footer>
    </Modal>
}