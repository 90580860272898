import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";
import EventRepository from "../../repositories/EventRepository";
import moment from "moment/moment";
// import {RecurringModal} from "./Modal/RecurringModal";
import {useNavigate} from "react-router-dom";
import {FormImageEvent} from "./FormImageEvent";
import {useTranslation} from "react-i18next";
import {Button} from "./Form/Button";

export function FormEvent({event = {}}) {
    const {t} = useTranslation();
    // const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState("");
    const [description, setDescription] = useState(null);
    const [budget, setBudget] = useState(0);
    const [address, setAddress] = useState(null);
    const [picture, setPicture] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [eventCloseDate, setEventCloseDate] = useState("");
    const [eventDateHour, setEventDateHour] = useState("00:00");
    const [eventCloseDateHour, setEventCloseDateHour] = useState("00:00");

    const navigate = useNavigate();

    function processDate(datetime) {
        if (null !== datetime) {
            const date = new Date(datetime);
            return moment(date).format('YYYY-MM-DD')
        }
    }

    const hasEvent = () => {
        return (event?.id ?? 0) > 0;
    }

    function processTime(datetime) {
        if (null !== datetime) {
            const date = new Date(datetime);
            return moment(date).format('hh:mm')
        }
    }

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        try {
            const dataToSend= {
                label,
                description,
                budget: Number(budget),
                address,
                picture,
                eventDate: eventDate + eventDateHour,
                eventCloseDate: eventCloseDate + eventCloseDateHour,
            }

            if (!hasEvent()) {
                const {data} = await EventRepository.post(dataToSend)
                navigate(`/event-detail/${data.id}`, {replace: true})
            } else {
                const {data} = await EventRepository.put(event.id, dataToSend)
                navigate(`/event-detail/${data.id}`, {replace: true})
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect( () => {
        if (hasEvent()) {
            setLabel(event.label);
            setPicture(event.picture);
            setDescription(event.description);
            setBudget(event.budget);
            setAddress(event.address);
            setPicture(event.picture);
            setEventDate(processDate(event.eventDate));
            setEventDateHour(processTime(event.eventDate))
            setEventCloseDate(processDate(event.eventCloseDate));
            setEventCloseDateHour(processTime(event.eventCloseDate));
        }
    }, [event]);

    return <>
        <div className="event-detail">
            <FormImageEvent picture={picture} setPicture={(picture) => setPicture(picture)}/>
        </div>
        <Form className="text-start mt-3 px-3" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label className="fw-bold">{t('form.event_name.label')}*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('form.event_name.placeholder')}
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label className="fw-bold">{t('form.description.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('form.description.placeholder')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label className="fw-bold">{t('form.location.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('form.location.placeholder')}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label className="fw-bold">{t('form.budget.label')}</Form.Label>
                        <div className="currency-input">
                            <Form.Control
                                type="number"
                                placeholder={t('form.budget.placeholder')}
                                value={budget}
                                onChange={(e) => setBudget(e.target.value)}
                            />
                            <span>EUR</span>
                        </div>
                    </Form.Group>
                </div>
            </div>
            <Form.Group className="mb-3" controlId="fullName">
                <Form.Label className="fw-bold">{t('form.dates.label')}</Form.Label>
                <div className="row mb-3">
                    <div className="col-6 input-icon">
                        <Form.Control
                            type="date"
                            value={eventDate}
                            onChange={(e) => setEventDate(e.target.value)}
                        />
                    </div>
                    <div className="col-6 input-icon">
                        <Form.Control
                            type="time"
                            value={eventDateHour}
                            onChange={(e) => setEventDateHour(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 input-icon">
                        <Form.Control
                            type="date"
                            value={eventCloseDate}
                            onChange={(e) => setEventCloseDate(e.target.value)}
                        />
                    </div>
                    <div className="col-6 input-icon">
                        <Form.Control
                            type="time"
                            value={eventCloseDateHour}
                            onChange={(e) => setEventCloseDateHour(e.target.value)}/>
                    </div>
                </div>
            </Form.Group>
            {/*<Button className="btn-icon btn-green" onClick={() => setShow(true)}>*/}
            {/*    <img src="/img/icon_swap.svg" alt=""/>*/}
            {/*    recurring*/}
            {/*</Button>*/}
            {/*<div className="row">*/}
            {/*    <div className="col-md-6">*/}
            {/*        <Form.Group className="my-3">*/}
            {/*            <Form.Label className="fw-bold">*/}
            {/*                Invites Closing*/}
            {/*            </Form.Label>*/}
            {/*            <Form.Select>*/}
            {/*                <option>One day before</option>*/}
            {/*                <option>One day before</option>*/}
            {/*                <option>One day before</option>*/}
            {/*                <option>One day before</option>*/}
            {/*                <option>One day before</option>*/}
            {/*            </Form.Select>*/}
            {/*        </Form.Group>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Button
                title="create_event.submit"
                isLoading={isLoading} />
        </Form>
        {/*<RecurringModal show={show} handleClose={() => setShow(false)}/>*/}
    </>
}