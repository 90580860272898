import {Swiper, SwiperSlide} from "swiper/react";
import {TitleWidget} from "./TitleWidget";
import {Carpoll} from "./Content/Carpoll";
import {Empty} from "./Content/Empty";
import {CreateCarpollModal} from "../Modal/CreateCarpollModal";
import {useState} from "react";

export function CarpollWidget({carpolls, event}) {
    const [show, setShow] = useState(false);

    return <div className="carpool-widget">
        <TitleWidget
            title="Carpool"
            icon="icon_carpool_black"
            handlerAdd={() => setShow(true)}
        />
        {carpolls.length === 0 ? <Empty title="Add carpool" callback={() => {}}/> :
            <Swiper slidesPerView={"auto"} spaceBetween={16}>
                <SwiperSlide>
                    <Carpoll/>
                </SwiperSlide>
            </Swiper>
        }

        <CreateCarpollModal
            show={show}
            handleClose={() => setShow(false)}
            event={event}
            callback={() => {}} />
    </div>
}