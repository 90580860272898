export function BullEvent({event}) {
    return <div className="current-event-widget p-2">
        <div className="d-flex gap-3">
            <div className="event-img flex-shrink-0">
                <img src={event.picture} alt={event.label} />
            </div>
            <ul className="list-unstyled px-0 m-0 small flex-grow-1">
                <li className="align-items-center d-flex flex-grow-1 gap-2 justify-content-between">
                    <span className="lh-sm">{event.label}</span>
                    <span className="d-flex gap-1">
                        <i className="icon-rounded active">
                          <img src="/img/icon_message_red.svg" alt=""/>
                        </i>
                        <i className="icon-rounded">
                          <img src="/img/icon_expense_red.svg" alt=""/>
                        </i>
                      </span>
                </li>
                <li className="text-blue fw-bold d-flex gap-1 small">
                    <img src="/img/icon_calendar-green.svg" alt=""/>
                    {event.eventDate}
                </li>
                <li className="d-flex gap-1 text-muted small">
                    <img src="/img/icon_location.svg" alt=""/>
                    {event.address}
                </li>
            </ul>
        </div>
    </div>
}