import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class ChecklistRepository extends AbstractRepository {
    put(id, data) {
        return axios.put(`/checklists/${id}`, data);
    }
    post(eventId, data) {
        return axios.post(`/event/${eventId}/checklists`, data);
    }
    delete(id) {
        return axios.delete(`/checklists/${id}`);
    }
    getAll(eventId) {
        return axios.get(`/event/${eventId}/checklists`);
    }
}

export default new ChecklistRepository();