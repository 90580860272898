import Form from "react-bootstrap/Form";
import React from "react";
import {NavLink} from "react-router-dom";
import UploadFileRepository from "../../repositories/UploadFileRepository";
import {toastMessage} from "../helpers/toast";

export function FormImageEvent({picture, setPicture}) {
    const uploadFile = async (e) => {
        const file = e.target.files[0]
        const { data, error } = await UploadFileRepository.upload(file, 'event');

        if (error) {
            toastMessage(error.message, 'error')
        } else {
            setPicture(data.fullPath)
        }
    }

    return <>
        <div className={`${picture === '' ? 'd-flex ' : ''}event-cover-img`}>
            {picture && <div>
                <img
                    onClick={() => setPicture("")}
                    className="img-fluid cover-img cursor-pointer"
                    alt=""
                    src={picture}
                />
            </div>}
            {!picture && <Form
                className="align-items-center d-flex justify-content-center w-100"
                encType="multipart/form-data"
            >
                <Form.Group>
                    <Form.Label className="fw-bold cursor-pointer" htmlFor="img-upload">
                        <img src="/img/icon_img_upload.svg" alt=""/>
                    </Form.Label>
                    <Form.Control
                        name="image"
                        type="file"
                        className="d-none"
                        id="img-upload"
                        onChange={uploadFile}
                    />
                </Form.Group>
                or
                <Form.Group>
                    <Form.Label className="fw-bold cursor-pointer" htmlFor="gif-upload">
                        <img src="/img/icon_gif_upload.svg" alt=""/>
                    </Form.Label>
                    <Form.Control
                        name="image"
                        type="file"
                        className="d-none"
                        id="gif-upload"
                        onChange={uploadFile}
                    />
                </Form.Group>
            </Form>}
            <div className="controls px-md-1">
                <NavLink to="/home" className="icon-transparent bg-white">
                    <img src="/img/icon_chevron_left.svg" alt=""/>
                </NavLink>
            </div>
        </div>
    </>
}