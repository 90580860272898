import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import {Notifications} from "../components/Header/Notifications";
import {Profile} from "../components/Header/Profile";
import NotificationRepository from "../../repositories/NotificationRepository";
import {useTranslation} from "react-i18next";

function Header() {
  const [show, setShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [count, setCount] = useState(0);
  const {t} = useTranslation();

  const getNotification = async () => {
    const {data} = await NotificationRepository.getAllNotRead()

    setCount(data.length)
  }

  const menu = [
    {
      "to": "/home",
      "label": "home",
      "icon": "icon_home"
    },
    {
      "to": "/expenses",
      "label": "expenses",
      "icon": "icon_expense"
    },
    {
      "to": "/messages",
      "label": "messages",
      "icon": "icon_messages"
    },
  ];

  useEffect( () => {
    getNotification()
  }, [])

  const location = useLocation();
  if (location.pathname !== '/event-detail' && location.pathname !== '/event-create' && !document.body.classList.contains('open-msg-box')) {
    return (
      <header>
        <Navbar>
          <Container>
            <div className="d-flex align-items-center">
              <Navbar.Brand to="/home" as={NavLink}>
                <img src="/img/logo.svg" alt=""/>
              </Navbar.Brand>
            </div>
            <div
              className="d-md-none back-cta position-relative start-0 unread mobile"
              onClick={() => setShow(true)}
            >
              <img src="/img/icon_notification.svg" alt=""/>
              {count > 0 && <span className="count">{count}</span>}
            </div>
            <div className="d-flex align-items-center theme-nav-outer">
              <Nav className="ms-auto theme-nav">
                {menu.map(({to, icon, label}, index) => (
                    <>
                      {index === 2 &&  <Nav.Link className="d-md-none icon-event" to="/event-create" as={NavLink}>
                        <div className="position-relative">
                          <img src="/img/icon_plus_primary.svg" alt=""/>
                        </div>
                      </Nav.Link>}

                      <Nav.Link to={to} as={NavLink} className="navitem">
                        <div className="position-relative">
                          <img
                              src={"/img/"+icon+".svg"}
                              className="outline-icon"
                              alt=""
                          />
                          <img
                              src={"/img/"+icon+"_solid.svg"}
                              className="solid-icon"
                              alt=""
                          />
                        </div>
                        {t(`header.menu.${label}`)}
                      </Nav.Link>
                    </>
                ))}
                <Nav.Link
                    onClick={() => setShowProfile(!showProfile)}
                    id="profileNavLink"
                    className="navitem"
                >
                  <div className="position-relative">
                    <img
                        src={`/img/icon_profile${showProfile ? '_solid' : ''}.svg`}
                        alt=""
                    />
                  </div>
                    {t("header.menu.profile")}
                </Nav.Link>
                <Nav.Link onClick={() => setShow(true)} className="d-none d-md-block">
                  <div className="position-relative unread">
                    <img
                        src="/img/icon_notification_gary.svg"
                        className="outline-icon"
                        alt=""
                    />
                    {count > 0 && <span className="count">{count}</span>}
                  </div>
                    {t("header.menu.notification")}
                </Nav.Link>
              </Nav>
            </div>
          </Container>
        </Navbar>
        <div className="doodle d-none">
          <p className="mb-0">Create events and invite people now!</p>
          <img alt="" src="/img/doodle.svg"/>
        </div>
        <NavLink to="/event-create" className="create-event d-md-block d-none">
          <img src="/img/icon_plus_primary.svg" alt=""/>
        </NavLink>

        <Profile show={showProfile} handleClose={() => setShowProfile(false)} />
        <Notifications show={show} handleClose={() => setShow(false)} />
      </header>
    );
  }
}

export default Header;
