import React, {useState} from "react";
import {PeopleListModal} from "../Modal/PeopleListModal";
import {NavLink} from "react-router-dom";
import {InvitePeopleModal} from "../Modal/InvitePeopleModal";

export function Header({event}) {
    const [show, setShow] = useState(false);
    const [invitePeopleShow, setInvitePeopleShow] = useState(false);

    return <>
        <h5 className="align-items-center d-flex fw-bold justify-content-between">
            <div className="d-lg-none back-cta position-relative start-0 me-3">
                <img src="/img/icon_chevron_left.svg" alt=""/>
            </div>
            <div className="d-flex align-items-center gap-2">
                <NavLink to={`/event-detail/${event.id}`}>
                    <div className="img-outer img-lg">
                        <img src={event.picture} alt=""/>
                    </div>
                </NavLink>
                {event.label}
            </div>
            <div
                className="back-cta position-relative start-0 me-3"
                onClick={() => setShow(true)}
            >
                <img src="/img/icon_add_user.svg" alt=""/>
            </div>
        </h5>
        <PeopleListModal
            show={show}
            handleClose={() => setShow(false)}
            handleInvite={() => {
                setShow(false)
                setInvitePeopleShow(true)
            }}
            event={event}
        />
        <InvitePeopleModal
            show={invitePeopleShow}
            handleClose={() => setInvitePeopleShow(false)}
            event={event}/>
    </>
}