import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import {Avatar} from "../User/Avatar";
import {ShareEvent} from "../ShareEvent";

export function InvitePeopleModal({show, handleClose, event}) {
    const FIRST_STEP = 'step1'
    const SECOND_STEP = 'step2'

    const [state, setStat] = useState(FIRST_STEP);

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal with-footer"
        scrollable
    >
        {(() => {
            switch (state) {
                case FIRST_STEP:
                    return (
                        <>
                            <Modal.Header className="justify-content-center fw-bold">
                                Invite people
                            </Modal.Header>
                            <Modal.Body>
                                <p className="text-gray">
                                    Easily invite people with a link or sms, Don't worry the invites comes directly from you.
                                </p>
                                <Form className="text-start">
                                    <ShareEvent event={event} />
                                    {/*<p className="text-center my-4 h5">or</p>*/}
                                    {/*<Button*/}
                                    {/*    variant="primary"*/}
                                    {/*    className="d-flex m-auto text-white"*/}
                                    {/*    onClick={() => setStat(SECOND_STEP)}*/}
                                    {/*>*/}
                                    {/*    Invite people*/}
                                    {/*</Button>*/}
                                </Form>
                                {/*<p className="d-flex justify-content-between my-3 fw-semibold">*/}
                                {/*    Selected <span>3 Contacts</span>*/}
                                {/*</p>*/}
                                {/*<div className="align-items-center justify-content-between d-flex gap-2 invite-user">*/}
                                {/*    <div className="align-items-center d-flex gap-2">*/}
                                {/*        <Avatar user={{name: 'Lovin Macx'}}/>*/}
                                {/*        <div className="user-name">Lovin Macx</div>*/}
                                {/*    </div>*/}
                                {/*    <img src="/img/icon_close_circle_gray.svg" alt=""/>*/}
                                {/*</div>*/}
                                {/*<div className="align-items-center justify-content-between d-flex gap-2 invite-user">*/}
                                {/*    <div className="align-items-center d-flex gap-2">*/}
                                {/*        <Avatar user={{name: 'Lovin Macx'}}/>*/}
                                {/*        <div className="user-name">Lovin Macx</div>*/}
                                {/*    </div>*/}
                                {/*    <img src="/img/icon_close_circle_gray.svg" alt=""/>*/}
                                {/*</div>*/}
                                {/*<div className="align-items-center justify-content-between d-flex gap-2 invite-user">*/}
                                {/*    <div className="align-items-center d-flex gap-2">*/}
                                {/*        <Avatar user={{name: 'Lovin Macx'}}/>*/}
                                {/*        <div className="user-name">Lovin Macx</div>*/}
                                {/*    </div>*/}
                                {/*    <img src="/img/icon_close_circle_gray.svg" alt=""/>*/}
                                {/*</div>*/}
                            </Modal.Body>
                        </>
                    );
                case SECOND_STEP:
                    return (
                        <>
                            <Modal.Header className="pb-0 fw-bold">
                                <div
                                    onClick={() => setStat(FIRST_STEP)}
                                    className="back-cta position-relative start-0"
                                >
                                    <img src="/img/icon_chevron_left.svg" alt=""/>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="title-form">
                                    <Form.Group>
                                        <div className="input-icon">
                                            <img
                                                src="/img/icon_search.svg"
                                                alt=""
                                                className="icon-left"
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder="Search Member"
                                                className="icon-single"
                                            />
                                        </div>
                                    </Form.Group>
                                    <p className="d-flex justify-content-between my-3 fw-semibold">
                                        Already Members <span>2 Contacts</span>
                                    </p>
                                    <div
                                        className="align-items-center justify-content-between d-flex gap-2 invite-user">
                                        <div className="align-items-center d-flex gap-2">
                                            <Avatar user={{name: 'Christoper'}}/>
                                            <div className="user-name">Christoper</div>
                                        </div>
                                        <Form.Check
                                            type="radio"
                                            name="members"
                                            className="d-flex justify-content-between flex-row-reverse p-0"
                                        />
                                    </div>
                                    <div
                                        className="align-items-center justify-content-between d-flex gap-2 invite-user">
                                        <div className="align-items-center d-flex gap-2">
                                            <Avatar user={{name: 'Lovin Macx'}}/>
                                            <div className="user-name">Lovin Macx</div>
                                        </div>
                                        <Form.Check
                                            type="radio"
                                            name="members"
                                            className="d-flex justify-content-between flex-row-reverse p-0"
                                        />
                                    </div>
                                    <div
                                        className="align-items-center justify-content-between d-flex gap-2 invite-user">
                                        <div className="align-items-center d-flex gap-2">
                                            <Avatar user={{name: 'Sarah Megan'}}/>
                                            <div className="user-name">Sarah Megan</div>
                                        </div>
                                        <Form.Check
                                            type="radio"
                                            name="members"
                                            className="d-flex justify-content-between flex-row-reverse p-0"
                                        />
                                    </div>
                                </Form>
                            </Modal.Body>
                        </>
                    );
                default:
                    return null;
            }
        })()}
        {/*<Modal.Footer>*/}
        {/*    <Button*/}
        {/*        variant="primary"*/}
        {/*        className="text-white w-100 px-3"*/}
        {/*        onClick={handleClose}*/}
        {/*    >*/}
        {/*        Continue*/}
        {/*    </Button>*/}
        {/*</Modal.Footer>*/}
    </Modal>
}