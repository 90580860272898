import {createContext, useEffect, useState} from "react";
import UserRepository from "../../../repositories/UserRepository";

export const UserContext = createContext({})
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({})

    const getUser = async () => {
        const {data} = await UserRepository.me();
        setUser(data)
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    )
}
