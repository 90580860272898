import {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import PollRepository from "../../../../repositories/PollRepository";
import {PollResultModal} from "../../Modal/PollResultModal";

export function Poll({poll, callback}) {
    const [countTotalAnswers, setCountTotalAnswers] = useState(0);
    const [show, setShow] = useState(false);
    const [userAnswerValueId, setUserAnswerValueId] = useState(poll.userAnswerValueId);

    useEffect(() => {
        processCountTotalAnswers()
    });

    function processCountTotalAnswers() {
        const total = poll.answers.reduce((accumulator, answer) => accumulator + answer.userAnswers.length, 0);
        setCountTotalAnswers(total)
    }

    const voteForAPoll = async (answerId) => {
        try {
            if (poll.userStatus === 'voted') {
                await PollRepository.put(poll.userAnswerId, answerId, {})
                setUserAnswerValueId(answerId)
            } else {
                await PollRepository.vote(answerId)
            }
        } catch (err) {
        }
    }

    const handlerRemove = async () => {
        await PollRepository.delete(poll.id)

        callback()
    }

    return (
        <>
            <div className="bg-light rounded-4 p-3 widget-outer">
                <div className="d-flex gap-3 justify-content-between">
                    <p className="mb-0">{poll.question}</p>
                    <img src="/img/icon_trash_red.svg" alt="" onClick={handlerRemove}/>
                </div>
                <p className="text-muted mb-0 f-12">Poll</p>
                <p className="text-muted mb-0 f-12">{countTotalAnswers} Votes</p>
                <Form>
                    {poll.answers?.map((answer) => (
                        <Form.Check
                            type="radio"
                            id={answer.id}
                            key={answer.id}
                            className="d-flex justify-content-between flex-row-reverse ps-0 mb-2"
                        >
                            <Form.Check.Input
                                type="radio"
                                name="eventPoll"
                                checked={answer.id === userAnswerValueId}
                                onClick={() => voteForAPoll(answer.id)}
                            />
                            <Form.Check.Label className="d-flex gap-2 w-100">
                              <span className="border-bottom custom-label">
                                {answer.json.value}
                              </span>
                            </Form.Check.Label>
                        </Form.Check>
                    ))}
                </Form>
            </div>
            <PollResultModal
                show={show}
                handleClose={() => setShow(false)}
            />
        </>
    );
}