import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class EventRepository extends AbstractRepository {
    get(id) {
        return axios.get(`/user/event/${id}`)
    }

    getAll(details = [], status = null) {
        const params = {}

        if (details !== []) {
            params['details'] = details
        }

        if (status !== null) {
            params['status'] = status
        }

        return axios.get('/user/events', {
            params
        })
    }

    delete(id) {
        return axios.put(`/event/${id}`, {status: 4})
    }

    post(data) {
        return axios.post('/event', data)
    }

    put(id, data) {
        return axios.put(`/event/${id}`, data)
    }

    getCode(id) {
        return axios.get(`/event-code/${id}`)
    }
}

export default new EventRepository();