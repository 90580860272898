export function TitleWidget({title, icon, handlerAdd = () => {}}) {
    return <div className="d-flex gap-2 align-items-center mt-4 mb-2 cursor-pointer">
        <img src={`/img/${icon}.svg`} alt={title}/>
        <h5 className="fw-bold mb-0">{title}</h5>
        <img
            className="ms-auto"
            src="/img/icon_add_red.svg"
            alt="add"
            onClick={handlerAdd}
        />
        {/*<img src="/img/icon_edit.svg" alt=""/>*/}
    </div>
}