import Form from "react-bootstrap/Form";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export function PollForm({callback, questionType}) {
    const defaultValue = questionType === 'location' ?
        {"json": {"url": "", "detail": ""}} :
        {"json": {"value": ""}};
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([defaultValue, defaultValue])
    const [isAnonymous, setIsAnonymous] = useState(false)
    const [isMultiple, setIsMultiple] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        callback({
            question,
            answers,
            isAnonymous,
            isMultiple,
            questionType
        })
    }, [question, answers, isAnonymous, isMultiple])

    const updateAnswer = (e, key, type = 'value') => {
        const oldAnswers = JSON.parse(JSON.stringify(answers))
        oldAnswers[key].json[type] = e.target.value;

        setAnswers(oldAnswers);
    }

    const addAnswer = () => {
        setAnswers([...answers, defaultValue])
    }

    return <Form className="title-form">
        <Form.Group className="mb-3">
            <Form.Control
                type="text"
                placeholder={t('modal.create_poll.question')}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
            />
        </Form.Group>
        <p className="mb-2">{t('modal.create_poll.options')}</p>
        {answers.map((answer, key) => (
            <Fragment key={key}>{questionType === 'location' ?
                <>
                    <Form.Group className="mb-3" controlId="fullName">
                        <div className="input-icon right-only">
                            <Form.Control
                                type="text"
                                placeholder={`${t('modal.create_poll.location')} ${key + 1}`}
                                className="icon-duo"
                                value={answer.json.url}
                                onChange={(e) => updateAnswer(e, key, 'url')}
                            />
                            <img
                                src="/img/icon_location.svg"
                                alt=""
                                className="icon-right"
                                height={24}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder={t('modal.create_poll.details')}
                            value={answer.json.detail}
                            onChange={(e) => updateAnswer(e, key, 'detail')}
                        />
                    </Form.Group>
                </> :
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        value={answer.json.value}
                        onChange={(e) => updateAnswer(e, key)}
                        placeholder={`${t('modal.create_poll.option')} ${key + 1}`}
                    />
                </Form.Group>
            }</Fragment>
        ))}
        <p className="mb-2 text-end text-blue cursor-pointer" onClick={addAnswer}>{t('modal.create_poll.add_answer')}</p>
        <div className="bg-light p-3 rounded-4 mb-3">
            <Form.Check
                type="switch"
                id="custom-switch"
                label={t('modal.create_poll.anonymous')}
                className="d-flex flex-row-reverse form-check form-switch justify-content-between p-0 mb-3"
                defaultChecked={isAnonymous}
                onChange={() => setIsAnonymous(!isAnonymous)}
            />
            <Form.Check
                type="switch"
                id="custom-switch"
                label={t('modal.create_poll.multiple')}
                className="d-flex flex-row-reverse form-check form-switch justify-content-between p-0"
                defaultChecked={isMultiple}
                onChange={() => setIsMultiple(!isMultiple)}
            />
        </div>
    </Form>
}