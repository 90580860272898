import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";

export function Header({title, subtitle = null, handleClose, handleSend = null}) {
    const {t} = useTranslation();

    return <Modal.Header className="pb-0">
        <Button variant="btn-link btn-sm" onClick={handleClose}>
            {t('modal.cancel')}
        </Button>
        <Modal.Title className="h6 mx-auto text-center">
            {subtitle !== null
                ? <>
                    <span className="fw-bold">{t(title)}</span>
                    <span className="d-block fw-normal">{t(subtitle)}</span>
                </>
                : t(title)
            }
        </Modal.Title>
        {handleSend !== null &&
            <Button variant="btn-link btn-sm" className="text-primary" onClick={handleSend}>
                {t('modal.send')}
            </Button>
        }
    </Modal.Header>
}