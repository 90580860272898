import {BullMessage} from "./BullMessage";
import {Header} from "./Header";
import Form from "react-bootstrap/Form";
import React, {useEffect, useRef, useState} from "react";
import {AddModal} from "../Modal/AddModal";
import MessageRepository from "../../../repositories/MessageRepository";
import EventRepository from "../../../repositories/EventRepository";
import {Loader} from "../Loader";
import {useTranslation} from "react-i18next";

export function Chat({eventId}) {
    const [show, setShow] = useState(false);
    const [scroll, setScroll] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [event, setEvent] = useState({});
    const messagesEndRef = useRef(null);
    const {t} = useTranslation();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const loadOldMessages = async () => {
        setScroll(false)
        await getMessages(true, page + 1)
        setTimeout(() => {
            setScroll(true)
        }, 1000);
    }

    const addMessage = async (e) => {
        e.preventDefault();
        try {
            const {data} = await MessageRepository.post(eventId, message)
            setMessage('')
            setMessages([...messages, {
                type: 'message',
                entity: data
            }])
        } catch (err) {
            console.log(err);
        }
    };

    const getEvent = async () => {
        const {data} = await EventRepository.get(eventId)
        setEvent(data)
    }

    const getMessages = async (incr = false, numberPage = 1) => {
        const {data} = await MessageRepository.getAll(eventId, numberPage)
        setMessages(incr ? [...data.items, ...messages] : data.items)
        setPage(data.page)
        setHasNextPage(data.hasNextPage)
        setIsLoading(false)
    }

    useEffect(() => {
        getEvent()
        getMessages()
    }, []);

    useEffect(() => {
        if (scroll) {
            messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
        }
    }, [messages])

    return <>
        <div className="box p-md-3 message-box">
            <Header event={event}/>
            <div className="chatroom">
                {/*<p className="chatdays">Today</p>*/}
                {hasNextPage && <div className="text-center">
                    <p className="load-messages cursor-pointer"onClick={loadOldMessages}>{t('chat.load_old_message')}</p>
                </div>}
                {isLoading && <div className="text-center">
                    <Loader/>
                </div>}
                <div className="chat-bulles">
                    {messages.map((message) => (
                        <BullMessage
                            key={`${message.type}_${message.entity.id}`}
                            message={message.entity}
                            type={message.type}
                            event={event}
                        />
                    ))}
                </div>
                <div ref={messagesEndRef}/>
            </div>
            <div className="chat-send">
                <Form className="title-form" onSubmit={addMessage}>
                    <Form.Group>
                        <div className="input-icon bar-left">
                            <a onClick={() => setShow(true)} className="cursor-pointer">
                                <img
                                    src="/img/icon_plus_circle-primay.svg"
                                    alt=""
                                    className="icon-left"
                                />
                            </a>
                            <Form.Control
                                type="text"
                                placeholder={t('chat.message')}
                                className="icon-duo"
                                id="message"
                                name="message"
                                onChange={handleMessageChange}
                                value={message}
                            />
                            <img onClick={addMessage}
                                 src="/img/icon_send.svg"
                                 alt=""
                                 className="cursor-pointer icon-right"
                            />
                        </div>
                    </Form.Group>
                </Form>
            </div>
        </div>
        <AddModal
            show={show}
            handleClose={() => setShow(false)}
            event={event}
        />
    </>
}