export function Empty({title, callback}) {
    return <div
        className="bg-light rounded-4 p-3 widget-outer gallery gallery-blank d-flex cursor-pointer flex-column justify-content-center"
        onClick={() => callback}
    >
        <div className="d-flex gap-2 justify-content-center align-items-center">
            {title}
            <img
                src="/img/icon_add_red.svg"
                alt=""
                height={16}
            />
        </div>
    </div>
}