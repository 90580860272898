import Form from "react-bootstrap/Form";
import {CreateChecklistModal} from "../../Modal/CreateChecklistModal";
import {useState} from "react";
import ChecklistRepository from "../../../../repositories/ChecklistRepository";
import {Avatar} from "../../User/Avatar";

export function Checklist({checklist, callback, withBackground = true}) {
    const [show, setShow] = useState(false);
    const handlerCheck = async (id) => {
        const items = checklist.items.map(item => {
            if (id === item.id) {
                item.checkbox = !item.checkbox;
            }

            return {
                id: item.id,
                checkbox: item.checkbox,
            }
        })

        await ChecklistRepository.put(checklist.id, {
            items
        })
    }
    const handlerRemove = async () => {
        await ChecklistRepository.delete(checklist.id)
        callback()
    }

    return <>
        <div className={`${withBackground ? 'bg-light ' : ''}rounded-4 p-3 widget-outer`}>
            <div className="d-flex gap-3 justify-content-between align-items-baseline">
                <p className="mb-1">{checklist.label}</p>
                <div className="d-flex gap-1">
                    <img
                        src="/img/icon_edit_black.svg"
                        alt=""
                        onClick={() => setShow(true)}
                    />
                    <img
                        src="/img/icon_trash_red.svg"
                        alt=""
                        onClick={handlerRemove}
                    />
                </div>
            </div>
            <Form className="text-start">
                <div className="theme-checkbox">
                    {checklist.items.map((proposition) => (
                        <Form.Check
                            key={proposition.id}
                            type="checkbox"
                            id="bread"
                            className={`border-0 d-flex form-check gap-2 mb-0 pb-0${checklist.checkbox ? ' ms-4' : ''}`}
                        >
                            {checklist.checkbox &&
                                <Form.Check.Input
                                    type="checkbox"
                                    name="eventCheckList"
                                    defaultChecked={proposition.checkbox}
                                    onChange={() => handlerCheck(proposition.id)}
                                />}
                            <Form.Check.Label className="d-flex gap-2 w-100 small">
                                {proposition.content}
                                {proposition.userCheck &&
                                    <Avatar user={proposition.userCheck} size='small' />
                                }
                            </Form.Check.Label>
                        </Form.Check>
                    ))}
                </div>
            </Form>
        </div>

        <CreateChecklistModal
            show={show}
            handleClose={() => setShow(false)}
            event={checklist.event}
            checklist={checklist}
            callback={() => callback()}
        />
    </>
}