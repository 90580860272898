import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import {FormEvent} from "../../components/FormEvent";

function EventCreate() {
    return <PageLayout>
        <Container>
            <div className="box-top">
                <div className="box p-md-3">
                    <FormEvent />
                </div>
            </div>
        </Container>
    </PageLayout>
}

export default EventCreate;
