import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Header} from "./Header";
import {useTranslation} from "react-i18next";

export function CreateCarpollModal({show, handleClose, callback}) {
    const {t} = useTranslation();
    const handleSave = () => {
        callback('carpoll')
        handleClose()
    }


    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
        scrollable
        centered
    >
        <Header
            title="modal.create_carpoll.title"
            handleClose={handleClose}
            handleSend={handleSave} />
        <Modal.Body>
            <Form className="title-form">
                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder={t('modal.create_carpoll.car_name')}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder={t('modal.create_carpoll.form')}/>
                </Form.Group>
                <div className="row">
                    <div className="align-items-center col-md-6 d-flex gap-3 text-nowrap offset-6">
                        {t('modal.create_carpoll.nb_seats')}
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="2"
                                className="text-center mb-2 form-control-color"
                            />
                        </Form.Group>
                    </div>
                </div>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={t('modal.create_carpoll.round')}
                    className="bg-light d-flex flex-row-reverse form-check form-switch justify-content-between p-0 p-3 rounded-4 mb-3"
                />
                <div className="row mb-3">
                    <div className="col-6 input-icon">
                        <Form.Control type="date"/>
                    </div>
                    <div className="col-6 input-icon">
                        <Form.Control type="time"/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-6 input-icon">
                        <Form.Control type="date"/>
                    </div>
                    <div className="col-6 input-icon">
                        <Form.Control type="time"/>
                    </div>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
}