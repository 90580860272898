import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {BullEvent} from "../../components/Event/BullEvent";
import {CreateExpenseModal} from "../../components/Modal/CreateExpenseModal";

function ExpensesDetail() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const event = {
        label: 'Very long event name that needs 2...',
        eventDate: "TBD",
        address: "Chez Dany",
    };

    return <>
        <PageLayout>
            <Container>
                <div className="box-top">
                    <div className="box px-3 p-md-3">
                        <h5 className="d-flex align-items-center fw-bold">
                            <div className="d-md-none back-cta position-relative start-0 me-3">
                                <img src="/img/icon_chevron_left.svg" alt=""/>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <div className="img-outer img-lg">
                                    <img src="/img/lahore.jpg" alt=""/>
                                </div>
                                Bachelor's Party
                            </div>
                        </h5>
                        <hr/>
                        <div className="row">
                            <div className="col-md-6">
                                <BullEvent event={event}/>
                            </div>
                        </div>
                        <Tabs defaultActiveKey="expenses" className="mb-3 theme-tab">
                            <Tab eventKey="expenses" title="Expenses">
                                <Button
                                    variant="primary btn-sm"
                                    type="submit"
                                    className="text-white mb-4"
                                    onClick={handleShow}
                                >
                                    Add an expense
                                </Button>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by <span className="user-initials">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by <span className="user-initials">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by{" "}
                              <span className="user-initials orange">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by{" "}
                              <span className="user-initials green">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by{" "}
                              <span className="user-initials orange">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="expense-detail-listing">
                                            <h5 className="d-flex justify-content-between price">
                                                Chips apero <strong>23,05€</strong>
                                            </h5>
                                            <p className="d-flex justify-content-between day">
                          <span className="align-items-center d-flex gap-1">
                            Paid by{" "}
                              <span className="user-initials green">MF</span>{" "}
                              <strong>Malik</strong>
                          </span>{" "}
                                                <span className="text-gray">today</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="balance" title="Balance">
                                <div className="balance mb-3">
                                    <p>
                                        <strong>Dany</strong>{" "}
                                        <span className="price danger">-74,28€</span>
                                    </p>
                                    <p>
                                        <strong>Malik</strong>{" "}
                                        <span className="price danger">-8,06€</span>
                                    </p>
                                    <p>
                                        <strong>Yoann</strong>{" "}
                                        <span className="price success">+57,05€</span>
                                    </p>
                                    <p>
                                        <strong>Nico</strong>{" "}
                                        <span className="price success">+8,06€</span>
                                    </p>
                                </div>
                                <div className="row px-4">
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="balance-listing">
                                            <div className="align-items-center d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <h5>
                                                        <strong>Dany</strong> owes <strong>Yoann</strong>
                                                    </h5>
                                                    <p className="align-items-center d-flex gap-1 justify-content-center mb-0">
                              <span
                                  className="text-body text-center text-decoration-underline text-gray"
                                  onClick={handleShow}
                              >
                                Paid ?
                              </span>
                                                        <img src="img/icon_check_square.svg" alt=""/>
                                                    </p>
                                                </div>
                                                <p className="amount h4">57,05€</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Container>
        </PageLayout>
        <CreateExpenseModal show={show} handleClose={() => setShow(false)}/>
    </>
}

export default ExpensesDetail;
