import Modal from "react-bootstrap/Modal";
import {CreatePollModal} from "./CreatePoll/CreatePollModal";
import {CreateCarpollModal} from "./CreateCarpollModal";
import {CreateChecklistModal} from "./CreateChecklistModal";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export function AddModal({show, handleClose, event, callback}) {
    const [createPollShow, setCreatePollShow] = useState(false);
    const [createCarPollShow, setCreateCarPollShow] = useState(false);
    const [createListShow, setCreateListShow] = useState(false);
    const {t} = useTranslation();

    const handlerClick = (type) => {
        handleClose()
        switch (type) {
            case 'list':
                setCreateListShow(true);
                break;
            case 'carpoll':
                setCreateCarPollShow(true);
                break;
            case 'poll':
                setCreatePollShow(true);
                break;
        }
    }
    const links = [
        // {
        //     'type': 'media',
        //     'icon': 'icon_image.svg',
        // },
        // {
        //     'type': 'file',
        //     'icon': 'icon_file.svg',
        // },
        {
            'type': 'list',
            'icon': 'icon_list.svg',
        },
        {
            'type': 'carpoll',
            'icon': 'icon_carpool.svg',
        },
        {
            'type': 'poll',
            'icon': 'icon_chart.svg',
        },
    ];

    return <>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="theme-modal modal-options"
            scrollable
            centered
        >
            <Modal.Body className="pb-4 h-auto">
                <ul className="list-unstyled m-0">
                    {links.map(({type, icon}) => (
                        <li className="cursor-pointer" key={type}>
                            <a onClick={() => handlerClick(type)}>
                                <img
                                    src={`/img/${icon}`}
                                    className="position-relative top-0"
                                    alt=""
                                />
                                {t(`modal.add.${type}`)}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="mt-2 text-center text-primary cursor-pointer cancel">
                    <a onClick={handleClose} className="d-block p-3">{t('modal.cancel')}</a>
                </div>
            </Modal.Body>
        </Modal>
        <CreatePollModal
            show={createPollShow}
            handleClose={() => setCreatePollShow(false)}
            event={event}
            callback={(type) => callback(type)}
        />
        <CreateCarpollModal
            show={createCarPollShow}
            handleClose={() => setCreateCarPollShow(false)}
            callback={(type) => callback(type)}
        />
        <CreateChecklistModal
            show={createListShow}
            handleClose={() => setCreateListShow(false)}
            event={event}
            callback={(type) => callback(type)}
        />
    </>
}