import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {useParams, useNavigate} from "react-router-dom";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "swiper/css";
import {animate, inView, motion, useInView} from "framer-motion";
import {AddModal} from "../../components/Modal/AddModal";
import {BullEvent} from "../../components/Event/BullEvent";
import {CarpollWidget} from "../../components/Widget/CarpollWidget";
import {CreateChatModal} from "../../components/Modal/CreateChatModal";
import {InvitePeopleModal} from "../../components/Modal/InvitePeopleModal";
import {ChecklistWidget} from "../../components/Widget/ChecklistWidget";
// import {MediaWidget} from "../../components/Widget/MediaWidget";
import {PollWidget} from "../../components/Widget/PollWidget";
import {HeaderEvent} from "../../components/Event/HeaderEvent";
import EventRepository from "../../../repositories/EventRepository";
import ChecklistRepository from "../../../repositories/ChecklistRepository";
import UserRoleRepository from "../../../repositories/UserRoleRepository";
import PollRepository from "../../../repositories/PollRepository";
import {Avatar} from "../../components/User/Avatar";
import {PeopleListModal} from "../../components/Modal/PeopleListModal";
import {useTranslation} from "react-i18next";

function EventDetail() {
    const {t} = useTranslation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [optShow, setOptShow] = useState(false);
    const [discussionShow, setDiscussionShow] = useState(false);
    const [invitePeopleShow, setinvitePeopleShow] = useState(false);
    const [countUserEvents, setCountUserEvents] = useState(false);
    const [event, setEvent] = useState([]);
    const [userRoleEvent, setUserRoleEvent] = useState({
        role: "guest",
    });
    const [userEvents, setUserEvents] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [polls, setPolls] = useState([]);
    const [loadPoll, setLoadPoll] = useState(true);
    const [loadChecklist, setLoadChecklist] = useState(true);
    const [loadCarpoll, setLoadCarpoll] = useState(true);
    let {id} = useParams();

    const handleDiscussionClose = () => setDiscussionShow(false)
    const handleInvitePeopleShow = () => setinvitePeopleShow(true)
    const invitePeopleClose = () => setinvitePeopleShow(false)

    const showCard = {};

    const hideCard = {
        opacity: 0,
        transitionEnd: {
            display: "block",
        },
    };

    const handleDiscussionShow = () => {
        if (Object.keys(event.messages).length === 0) {
            setDiscussionShow(true)
        } else if (Object.keys(event.messages).length !== 0) {
            navigate(`/chat/${id}`, {replace: true})
        }
    };

    useEffect(() => {
        getUserRole()
        getEvent()
    }, []);

    useEffect(() => {
        loadChecklist && getChecklist()
        loadPoll && getPolls()
    }, [loadPoll, loadCarpoll, loadChecklist]);

    const reload = async (type) => {
        switch (type) {
            case 'checklist':
                setLoadChecklist(true)
                break;
            case 'poll':
                setLoadPoll(true)
                break;
            case 'cartpoll':
                setLoadCarpoll(true)
                break;
        }
    }

    useLayoutEffect(() => {
        inView("float-card", ({target}) => {
            animate(target, {opacity: 1})
        });
    }, [isInView]);

    async function getUserRole() {
        const {data} = await UserRoleRepository.get(id);
        if (data === null) {
            navigate('/404', {replace: true})
        }
        setUserRoleEvent(data)
    }

    async function getEvent() {
        const {data} = await EventRepository.get(id)
        const userEvents = data.userEvents
        setEvent(data)
        setCountUserEvents(userEvents.length)
        setUserEvents(userEvents)
    }

    async function getPolls() {
        const {data} = await PollRepository.getAll(id);
        setPolls(data)
        setLoadPoll(false)
    }

    async function getChecklist() {
        const {data} = await ChecklistRepository.getAll(id);
        setChecklists(data)
        setLoadChecklist(false)
    }

    return <>
        <PageLayout>
            <Container>
                <div className="box-top">
                    <div className="box overflow-y-auto p-md-3">
                        <div className="event-detail">
                            <HeaderEvent event={event}/>
                            <motion.div
                                className="event-card"
                                animate={isInView ? showCard : hideCard}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="fw-bold">{event.label}</h4>
                                        <ul className="mb-0 p-0 list-unstyled event-info small">
                                            <li className="d-flex gap-1">
                                                <Avatar user={event.userCreator} size='small'/>
                                                {t('event_detail.creator')} <strong>{event.userCreator?.name}</strong>
                                            </li>
                                            <li
                                                ref={ref}
                                                className="text-blue fw-bold d-flex gap-1"
                                            >
                                                <img src="/img/icon_calendar-green.svg" alt=""/>
                                                {event.eventDate || t('event_detail.tbd')}
                                            </li>
                                            <li className="d-flex gap-1">
                                                <img src="/img/icon_location_dark.svg" alt=""/>
                                                {event.address || t('event_detail.tbd')}
                                            </li>
                                            {event.budget !== 0 &&
                                                <li className="text-danger f-10 fw-bold">
                                                    <li className="text-gray d-flex gap-1">
                                                        <img src="/img/icon_dollar_square.svg" alt=""/>
                                                        {event.budget}
                                                    </li>
                                                </li>
                                            }
                                            {event.eventCloseDate !== null &&
                                                <li className="text-danger f-10 fw-bold">
                                                    { t('event_detail.last_date', {date: event.eventCloseDate}) }
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            className="align-items-md-end d-flex gap-3 h-100 justify-content-md-end">
                                            <Button
                                                type="submit"
                                                className="btn-white btn-icon mt-3 btn-gray"
                                                onClick={handleDiscussionShow}
                                            >
                                                <img src="/img/icon_messages.svg" alt=""/>
                                                {t('event_detail.discussion')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="btn-white btn-icon mt-3 "
                                            >
                                                <img src="/img/icon_expense_red.svg" alt=""/>
                                                {t('event_detail.expenses')}
                                            </Button>
                                            {userRoleEvent.role !== "creator" && (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        className="btn-white btn-icon text-dark mt-3"
                                                    >
                                                        <img src="/img/icon_tick.svg" alt=""/>
                                                        {t('event_detail.going')}
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        className="btn-white btn-icon text-dark mt-3 "
                                                    >
                                                        <img src="/img/icon_cross.svg" alt=""/>
                                                        {t('event_detail.not_going')}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                            {!isInView && (
                                <motion.div
                                    className="float-card"
                                    initial={{scale: 0}}
                                    animate={{scale: 1}}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 30,
                                    }}
                                >
                                    <BullEvent event={event}/>
                                </motion.div>
                            )}
                            <div className="event-about mt-3 mt-md-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="small">
                                            {event.description}
                                            <span className="text-blue float-end small fw-bold">{t('event_detail.read_more')}</span>
                                        </p>
                                    </div>
                                    <div className="col-md-4 offset-md-2 ">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="text-muted text-md-end">
                                                <span className="fw-bold text-dark">{countUserEvents}</span> {t('event_detail.nb_people_invited', {count: countUserEvents})}
                                            </h5>
                                            <div
                                                className="d-flex gap-1 justify-content-md-end align-items-center cursor-pointer"
                                                onClick={handleInvitePeopleShow}
                                            >
                                                <img src="/img/icon_profile_add.svg" alt=""/>
                                                {t('event_detail.add_people')}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-4 justify-content-md-between">
                                            <div className="user-thumb-overlap">
                                                {userEvents.slice(0, 5).map((userEvent) => (
                                                    <Avatar user={userEvent.author} key={userEvent.author.id} />
                                                ))}
                                                {userEvents.length > 5 && (
                                                    <span
                                                        className="bg-light d-flex justify-content-center align-items-center">
                                                          {userEvents.length - 5}
                                                        </span>
                                                )}
                                            </div>
                                            <div className="cursor-pointer" onClick={() => setShow(true)}>
                                                {t('event_detail.view_all')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-send event-widget">
                                    <button
                                        className="d-flex m-auto m-md-0 text-white gap-2 add-cta btn btn-primary"
                                        onClick={() => setOptShow(true)}
                                    >
                                        {t('event_detail.add_widget')}
                                        <img src="/img/icon_plus_circle_white.svg" alt=""/>
                                    </button>
                                </div>

                                <PollWidget
                                    polls={polls}
                                    event={event}
                                    callback={() => setLoadPoll(true)}
                                />
                                <ChecklistWidget
                                    checklists={checklists}
                                    event={event}
                                    callback={() => setLoadChecklist(true)}
                                />
                                {/*<MediaWidget />*/}
                                <CarpollWidget
                                    carpolls={[{}]}
                                    event={event}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </PageLayout>
        <PeopleListModal
            show={show}
            handleClose={() => setShow(false)}
            handleInvite={() => {
                setShow(false)
                setinvitePeopleShow(true)
            }}
            event={event}
        />
        <CreateChatModal
            show={discussionShow}
            onHide={handleDiscussionClose}
            event={event}/>
        <InvitePeopleModal
            show={invitePeopleShow}
            handleClose={invitePeopleClose}
            event={event}/>
        <AddModal
            show={optShow}
            event={event}
            handleClose={() => setOptShow(false)}
            callback={(type) => reload(type)}
        />
    </>
}

export default EventDetail;
