import {createClient} from "@supabase/supabase-js";
import {v4 as uuidv4} from "uuid";

class UploadFileRepository {

    supabase = null;

    constructor() {
        this.supabase = createClient(
            process.env.REACT_APP_SUPABASE_URL,
            process.env.REACT_APP_SUPABASE_ANON_KEY,
        )
    }

    async upload(file, type) {
        const { data, error, ...rest } = await this.supabase
            .storage
            .from(process.env.REACT_APP_SUPABASE_BUCKET)
            .upload(`${type}/${uuidv4()}.${file.name.split('.').pop()}`, file, {
                cacheControl: '3600',
                upsert: false
            })

        if (data.fullPath) {
            data.fullPath = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${data.fullPath}`;
        }

        return {data, error, ...rest};
    }
}

export default new UploadFileRepository();