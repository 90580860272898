import {Header} from "./Header";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import ContactRepository from "../../../../repositories/ContactRepository";
import {toastMessage} from "../../../helpers/toast";
import {useTranslation} from "react-i18next";
import {Button} from "../../Form/Button";

export function Contact({user, onClose}) {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')

    useEffect(() => {
        setName(user.name)
        setEmail(user.email)
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            await ContactRepository.post({
                name, email, subject, content
            })
            setName("")
            setEmail("")
            setSubject("")
            setContent("")
            toastMessage('Contact send');
        } catch (e) {
            toastMessage(e.message, 'error');
        } finally {
            setIsLoading(false)
        }
    }

    return <div className="user-profile my-4">
        <Header title='contact.title' onClose={onClose}/>
        <Form className="text-start mt-4 pt-2" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="firstname">
                <Form.Label className="fw-bold">{t('form.name.label')}*</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('form.name.placeholder')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label className="fw-bold">{t('form.email.label')}*</Form.Label>
                <Form.Control
                    type="email"
                    placeholder={t('form.email.placeholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="subjet">
                <Form.Label className="fw-bold">{t('form.subject.label')}*</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('form.subject.placeholder')}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="content">
                <Form.Label className="fw-bold">{t('form.message.label')}*</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('form.message.placeholder')}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
            </Form.Group>
            <div className="text-center">
                <Button
                    title="contact.submit"
                    isLoading={isLoading} />
            </div>
        </Form>
    </div>
}