import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class MessageRepository extends AbstractRepository {
    post(eventId, content) {
        return axios.post(`/message/event/${eventId}`, {content});
    }

    getAll(eventId, page = 0) {
        const query = {}

        if (page > 0) {
            query['params'] = {
                page
            }
        }

        return axios.get(`/message/event/${eventId}`, query);
    }
}

export default new MessageRepository();