import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import EventRepository from "../../../repositories/EventRepository";
import {useTranslation} from "react-i18next";

function Main() {
    const {t} = useTranslation();
    const [events, setEvents] = useState([]);
    const [invitedEvents, setInvitedEvents] = useState([]);
    const [showAll, setShowAll] = useState(3);
    const [searchEventValue, setSearchEventValue] = useState("")
    const navigate = useNavigate();
    const handleClick = (eventId) => {
        navigate("/event-detail/" + eventId, {replace: true});
    };

    const startSearch = (value) => {
        setSearchEventValue(value);
        if (!value) {
            viewAllClick();
        } else {
            viewAllClick('search');

        }
    }
    const viewAllClick = (origin) => {
        if (showAll === 3 || origin === 'search') {
            setShowAll(999);
        } else {
            setShowAll(3);
        }
    };
    const getInvitedEvents = async () => {
        const {data} = await EventRepository.getAll([], 1)

        setInvitedEvents(data);
    }
    const getEvents = async () => {
        const {data} = await EventRepository.getAll([], 2)

        setEvents(data);
    }

    useEffect(() => {
        getEvents()
        getInvitedEvents()
    }, []);

    return <PageLayout>
        <Container>
            <div className="box p-md-3">
                <div className="align-items-center d-flex justify-content-between m-0 page-title main">
                    <Form className="title-form">
                        <Form.Group>
                            <div className="input-icon">
                                <img
                                    src="/img/icon_search.svg"
                                    alt=""
                                    className="icon-left"
                                />
                                <Form.Control
                                    type="text"
                                    placeholder={t('home.search')}
                                    className="icon-single"
                                    value={searchEventValue}
                                    onChange={e => startSearch(e.target.value)}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                    <img className="options" src="/img/icon_ellipsis_vertical.svg" alt=""/>
                </div>
                <Tabs defaultActiveKey="myevents" className=" mt-4 pt-1 mb-4 theme-tab justify-content-start">
                    <Tab eventKey="myevents" title="My events">
                        <h6 className="d-flex justify-content-between mb-0">
                            <span className="fw-bold">{t('home.scheduled')}</span>
                            <span className="fw-normal f-12" onClick={viewAllClick}>
                                {showAll === 3 ? t('home.view_all') : t('home.view_default')}
                            </span>
                        </h6>
                        <div className="row">
                            {events.filter(event => event.eventDate !== null)
                                .filter(event => event.label.match(new RegExp(searchEventValue, "i")))
                                .slice(0, showAll)
                                .map((event) => (
                                    <div className="col-md-6" key={event.id}>
                                        <div className="event-outer" onClick={() => handleClick(event.id)}>
                                            <div className="event-img">
                                                <img src={event.picture} alt=""/>
                                            </div>
                                            <div className="event-content">
                                                <h6 className="fw-bold">{event.label}</h6>
                                                <ul className="mb-0 p-0 list-unstyled event-info">
                                                    <li className="text-blue fw-bold">
                                                        <img src="/img/icon_calendar-green.svg" alt=""/>
                                                        {event.eventDate}
                                                    </li>
                                                    <li className="text-gray">
                                                        <img src="/img/icon_location.svg" alt=""/>
                                                        {event.address}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                        </div>
                        <span className="fw-normal f-12 three-dot" onClick={viewAllClick}>
                                    <center>  {showAll === 3 ? "..." : ''}</center>
                                </span>
                        <h6 className="d-flex justify-content-between mb-0 mt-4">
                            <span className="fw-bold">{t('home.not_scheduled')}</span>
                            {/*<span className="fw-normal f-12">View All</span>*/}
                        </h6>
                        {/*<p className="text-center text-gray mt-2 mb-0">Today</p>*/}
                        <div className="row">
                            {events.filter(event => event.eventDate === null)
                                .filter(event => event.label.match(new RegExp(searchEventValue, "i")))
                                .map((scheduledEvent) => (
                                    <div className="col-md-6" key={scheduledEvent.id}>
                                        <div className="event-outer"
                                             onClick={() => handleClick(scheduledEvent.id)}>
                                            <div className="event-img">
                                                <img src={scheduledEvent.picture} alt=""/>
                                            </div>
                                            <div className="event-content">
                                                <h6 className="fw-bold">{scheduledEvent.label}</h6>
                                                <ul className="mb-0 p-0 list-unstyled event-info">
                                                    <li className="text-blue fw-bold">
                                                        <img src="/img/icon_calendar-green.svg" alt=""/>
                                                        {scheduledEvent.eventDate}
                                                        {/*Friday<span className="dot"></span>27 Dec 2022*/}
                                                        {/*<span className="dot"></span>10:00 PM*/}
                                                    </li>
                                                    <li className="text-gray">
                                                        <img src="/img/icon_location.svg" alt=""/>
                                                        {scheduledEvent.address}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Tab>
                    <Tab eventKey="invites" title={"Invited (" + invitedEvents.length + ")"}>
                        <div className="row">
                            {invitedEvents.filter(event => event.eventDate !== null)
                                .filter(event => event.label.match(new RegExp(searchEventValue, "i")))
                                .slice(0, showAll)
                                .map((invitedEvent) => (
                                    <div className="col-md-6" key={invitedEvent.id}>
                                        <div className="event-outer"
                                             onClick={() => handleClick(invitedEvent.id)}>
                                            <div className="event-img">
                                                <img src={invitedEvent.picture} alt=""/>
                                            </div>
                                            <div className="event-content">
                                                <h6 className="fw-bold">{invitedEvent.label}</h6>
                                                <ul className="mb-0 p-0 list-unstyled event-info">
                                                    <li className="text-blue fw-bold">
                                                        <img src="/img/icon_calendar-green.svg" alt=""/>
                                                        {invitedEvent.eventDate}
                                                    </li>
                                                    <li className="text-gray">
                                                        <img src="/img/icon_location.svg" alt=""/>
                                                        {invitedEvent.address}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Container>
    </PageLayout>
}

export default Main;
