import {Swiper, SwiperSlide} from "swiper/react";
import {Checklist} from "./Content/Checklist";
import {TitleWidget} from "./TitleWidget";
import {CreateChecklistModal} from "../Modal/CreateChecklistModal";
import {useState} from "react";
import {Empty} from "./Content/Empty";

export function ChecklistWidget({checklists, event, callback}) {
    const [show, setShow] = useState(false);

    return <div className="list-widget">
        <TitleWidget
            title="List"
            icon="icon_list_black"
            handlerAdd={() => setShow(true)}
        />
        {checklists.length === 0 ? <Empty title="Add list" callback={() => setShow(true)}/> :
            <Swiper slidesPerView={"auto"} spaceBetween={16}>
                {checklists.map((checklist) => (
                    <SwiperSlide key={checklist.id}>
                        <Checklist
                            checklist={checklist}
                            callback={() => callback()}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        }

        <CreateChecklistModal
            show={show}
            handleClose={() => setShow(false)}
            event={event}
            callback={() => callback()}
        />
    </div>
}