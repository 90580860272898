import {Swiper, SwiperSlide} from "swiper/react";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import {TitleWidget} from "./TitleWidget";
import {Poll} from "./Content/Poll";
import {CreatePollModal} from "../Modal/CreatePoll/CreatePollModal";
import {useState} from "react";
import {Empty} from "./Content/Empty";

export function PollWidget({polls, event, callback}) {
    const [show, setShow] = useState(false);

    return <div className="polls-widget">
        <TitleWidget title="Polls" icon="icon_polls"/>

        {polls.length === 0 ? <Empty title="Add poll" callback={() => setShow(true)}/> :
            <Swiper slidesPerView={"auto"} spaceBetween={16}>
                {polls.map((poll) => (
                    <SwiperSlide>
                        <Poll poll={poll} callback={() => callback()}/>
                    </SwiperSlide>
                ))}
                <SwiperSlide>
                    <div className="bg-light rounded-4 p-3 widget-outer">
                        <div className="d-flex gap-3 justify-content-between">
                            <p className="mb-0">
                                Where we have to make a dinner?
                            </p>
                            <img src="/img/icon_trash_red.svg" alt=""/>
                        </div>
                        <p className="text-muted mb-0 f-12">Location Poll</p>
                        <span className="user-initials green mb-2">QP</span>
                        <Form>
                            <Form.Check
                                type="radio"
                                id="pont"
                                className="d-flex justify-content-between flex-row-reverse ps-0 mb-2"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100 align-items-center">
                                    <div className="poll-thumb">
                                        <img
                                            className="icon"
                                            src="/img/icon_location_white.svg"
                                            alt=""
                                        />
                                        <img
                                            className="bg"
                                            src="https://media.wired.com/photos/5a6a61938c669c70314b300d/master/w_2560%2Cc_limit/Google-Map-US_10.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <span className="border-bottom custom-label">
                                      Le pont
                                    </span>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                                type="radio"
                                id="tunnel"
                                className="d-flex justify-content-between flex-row-reverse ps-0 mb-2"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100 align-items-center">
                                    <div className="poll-thumb">
                                        <img
                                            className="icon"
                                            src="/img/icon_location_white.svg"
                                            alt=""
                                        />
                                        <img
                                            className="bg"
                                            src="https://media.wired.com/photos/5a6a61938c669c70314b300d/master/w_2560%2Cc_limit/Google-Map-US_10.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <span className="border-bottom custom-label">
                                      Le tunnel
                                    </span>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                                type="radio"
                                id="chemin"
                                className="d-flex justify-content-between flex-row-reverse ps-0 mb-2"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100 align-items-center">
                                    <div className="poll-thumb">
                                        <img
                                            className="icon"
                                            src="/img/icon_location_white.svg"
                                            alt=""
                                        />
                                        <img
                                            className="bg"
                                            src="https://media.wired.com/photos/5a6a61938c669c70314b300d/master/w_2560%2Cc_limit/Google-Map-US_10.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <span className="border-bottom custom-label">
                                      Le Chemin
                                    </span>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="bg-light rounded-4 p-3 widget-outer">
                        <div className="d-flex gap-3 justify-content-between">
                            <p className="mb-0">When do we do it ?</p>
                            <img src="/img/icon_trash_red.svg" alt=""/>
                        </div>
                        <p className="text-muted mb-0 f-12">Time Poll</p>
                        <div className="user-thumb-overlap initials-sm mb-2">
                            <span className="user-initials">QP</span>
                            <span className="user-initials green">SJ</span>
                            <span className="user-initials orange">AQ</span>
                            <span className="user-initials green">QP</span>
                        </div>
                        <Form>
                            <Form.Check
                                type="radio"
                                id="21st"
                                className="d-flex justify-content-between flex-row-reverse ps-0 mb-2"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100">
                                    <div className="custom-label d-flex gap-2 align-items-center">
                                        <div className="text-blue f-10 fw-bold">
                                            33%
                                        </div>
                                        <div className="d-flex flex-column">
                                            21/06/2023
                                            <ProgressBar now="33"/>
                                        </div>
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                                type="radio"
                                id="21st"
                                className="d-flex justify-content-between flex-row-reverse ps-0"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100">
                                    <div className="custom-label d-flex gap-2 align-items-center">
                                        <div className="text-blue f-10 fw-bold">
                                            67%
                                        </div>
                                        <div className="d-flex flex-column">
                                            21/06/2023
                                            <ProgressBar now="67"/>
                                        </div>
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form>
                        <p className="text-blue mt-2 mb-0 text-center fw-bold f-12">
                            View Results
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="bg-light rounded-4 p-3 widget-outer">
                        <div className="d-flex gap-3 justify-content-between">
                            <p className="mb-0">
                                Where we have to make a dinner?
                            </p>
                            <img src="/img/icon_trash_red.svg" alt=""/>
                        </div>
                        <p className="text-muted mb-0 f-12">Poll</p>
                        <div className="user-thumb-overlap image-sm mb-2">
                            <div className="img-frame">
                                <img src="/img/user-1.jpg" alt=""/>
                            </div>
                            <div className="img-frame">
                                <img src="/img/user-2.jpg" alt=""/>
                            </div>
                            <div className="img-frame">
                                <img src="/img/user-3.jpg" alt=""/>
                            </div>
                            <div className="img-frame">
                                <img src="/img/user-4.jpg" alt=""/>
                            </div>
                        </div>
                        <Form>
                            <Form.Check
                                type="radio"
                                id="khi"
                                className="d-flex justify-content-between flex-row-reverse ps-0 my-4"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100">
                                    <div className="custom-label d-flex gap-2 align-items-center">
                                        <div className="text-blue f-10 fw-bold">
                                            33%
                                        </div>
                                        <div className="d-flex flex-column gap-2">
                                            <div className="d-flex gap-2">
                                                <div className="poll-label-img">
                                                    <img src="/img/karachi.jpg" alt=""/>
                                                </div>
                                                <div className="d-flex flex-column gap-2">
                                            <span className="small text-decoration-underline text-blue-50">
                                              Karachi
                                            </span>
                                                    <span className="small">$300</span>
                                                </div>
                                            </div>
                                            <ProgressBar now="33"/>
                                        </div>
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                                type="radio"
                                id="lhr"
                                className="d-flex justify-content-between flex-row-reverse ps-0 my-4"
                            >
                                <Form.Check.Input type="radio" name="eventPoll"/>
                                <Form.Check.Label className="d-flex gap-2 w-100">
                                    <div className="custom-label d-flex gap-2 align-items-center">
                                        <div className="text-blue f-10 fw-bold">
                                            67%
                                        </div>
                                        <div className="d-flex flex-column gap-2">
                                            <div className="d-flex gap-2">
                                                <div className="poll-label-img">
                                                    <img src="/img/lahore.jpg" alt=""/>
                                                </div>
                                                <div className="d-flex flex-column gap-2">
                                            <span className="small text-decoration-underline text-blue-50">
                                              Lahore
                                            </span>
                                                    <span className="small">$200</span>
                                                </div>
                                            </div>
                                            <ProgressBar now="67"/>
                                        </div>
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form>
                        <p className="text-blue mt-2 mb-0 text-center fw-bold f-12">
                            View Results
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        }

        <CreatePollModal
            show={show}
            handleClose={() => setShow(false)}
            event={event}
            callback={() => callback()}
        />
    </div>
}