import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class NotificationRepository extends AbstractRepository {
    getAll() {
        return axios.get('/notifications/');
    }

    getAllNotRead() {
        return axios.get('/notifications/', {
            params: {
                isRead: false
            }
        });
    }

    read(id) {
        return axios.put(`/notifications/${id}`, {
            'read': true,
        });
    }
}

export default new NotificationRepository();