import Offcanvas from "react-bootstrap/Offcanvas";
import {NavLink} from "react-router-dom";
import {useContext, useState} from "react";
import {Avatar} from "../User/Avatar";
import {Edit} from "./Profile/Edit";
import {Language} from "./Profile/Language";
import {UserContext} from "../../layout/context/UserProvider";
import {logout} from "../../helpers/auth";
import {FAQ} from "./Profile/FAQ";
import {TermsConditions} from "./Profile/TermsConditions";
import {PrivacyPolicy} from "./Profile/PrivacyPolicy";
import {Contact} from "./Profile/Contact";
import {DeleteAccountModal} from "../Modal/DeleteAccountModal";
import {useTranslation} from "react-i18next";

const LINK = {
    HOME: 'home',
    EDIT: 'edit',
    LANGUAGE: 'language',
    FAQ: 'faq',
    CONTACT: 'contact',
    TERMS_CONDITIONS: 'terms_conditions',
    PRIVACY_POLICY: 'privacy_policy',
}

export function Profile({show, handleClose}) {
    const {t} = useTranslation();
    const [step, setStep] = useState(LINK.HOME);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const {user} = useContext(UserContext)

    const handleClick = (step) => {
        setStep(step)
    }

    const handlerLogout = () => {
        logout()
    };

    return <>
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Body>
                {(() => {
                    switch (step) {
                        case LINK.HOME:
                            return (
                                <>
                                    <div className="user-profile mb-4">
                                        <div className="profile-img">
                                            <Avatar user={user} size='big'/>
                                            <h4 className="fw-bold">{user?.name}</h4>
                                        </div>
                                        <h5 className="mb-3">{t('profile.account')}</h5>
                                        <ul className="list-unstyled p-0 profile-listing">
                                            <li>
                                            <span
                                                onClick={() => handleClick(LINK.EDIT)}
                                                className="d-flex justify-content-between gap-3 cursor-pointer">
                                                <div className="d-flex align-items-center gap-3">
                                                    <img src="/img/icon_edit_profile.svg" alt=""/>
                                                    {t('profile.links.edit_profile')}
                                                </div>
                                                <img src="/img/icon_chevron_right.svg" alt=""/>
                                            </span>
                                            </li>
                                            <li>
                                            <span
                                                onClick={() => handleClick(LINK.LANGUAGE)}
                                                className="d-flex justify-content-between gap-3  cursor-pointer"
                                            >
                                                <div className="d-flex align-items-center gap-3">
                                                    <img src="/img/icon_globe.svg" alt=""/>
                                                    {t('profile.links.language')}
                                                </div>
                                                <img src="/img/icon_chevron_right.svg" alt=""/>
                                            </span>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    onClick={() => handlerLogout}
                                                    className="d-flex justify-content-between gap-3"
                                                >
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img src="/img/icon_logout.svg" alt=""/>
                                                        {t('profile.links.logout')}
                                                    </div>
                                                    <img src="/img/icon_chevron_right.svg" alt=""/>
                                                </NavLink>
                                            </li>
                                        </ul>
                                        <h5 className="mb-3">{t('profile.support')}</h5>
                                        <ul className="list-unstyled p-0 profile-listing">
                                            <li>
                                            <span
                                                onClick={() => handleClick(LINK.FAQ)}
                                                className="d-flex justify-content-between gap-3 cursor-pointer"
                                            >
                                                <div className="d-flex align-items-center gap-3">
                                                    <img src="/img/icon_faq.svg" alt=""/>
                                                    FAQ
                                                </div>
                                                <img src="/img/icon_chevron_right.svg" alt=""/>
                                            </span>
                                            </li>
                                            <li>
                                                <NavLink
                                                    onClick={() => handleClick(LINK.CONTACT)}
                                                    className="d-flex justify-content-between gap-3"
                                                >
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img src="/img/icon_envelope_circle.svg" alt=""/>
                                                        {t('profile.links.contact')}
                                                    </div>
                                                    <img src="/img/icon_chevron_right.svg" alt=""/>
                                                </NavLink>
                                            </li>
                                            <li>
                                            <span
                                                onClick={() => handleClick(LINK.TERMS_CONDITIONS)}
                                                className="d-flex justify-content-between gap-3 cursor-pointer"
                                            >
                                                <div className="d-flex align-items-center gap-3">
                                                    <img src="/img/icon_info.svg" alt=""/>
                                                    {t('profile.links.terms_conditions')}
                                                </div>
                                                <img src="/img/icon_chevron_right.svg" alt=""/>
                                            </span>
                                            </li>
                                            <li>
                                            <span
                                                onClick={() => handleClick(LINK.PRIVACY_POLICY)}
                                                className="d-flex justify-content-between gap-3 cursor-pointer"
                                            >
                                                <div className="d-flex align-items-center gap-3">
                                                    <img src="/img/icon_lock.svg" alt=""/>
                                                    {t('profile.links.privacy_policy')}
                                                </div>
                                                <img src="/img/icon_chevron_right.svg" alt=""/>
                                            </span>
                                            </li>
                                        </ul>
                                        <h6 className="text-primary cursor-pointer" onClick={() => setShowDeleteAccount(true)}>
                                            {t('profile.links.delete_account')}
                                        </h6>
                                    </div>
                                </>
                            );
                        case LINK.EDIT:
                            return <Edit user={user} onClose={() => handleClick(LINK.HOME)}/>
                        case LINK.LANGUAGE:
                            return <Language onClose={() => handleClick(LINK.HOME)}/>
                        case LINK.FAQ:
                            return <FAQ onClose={() => handleClick(LINK.HOME)}/>
                        case LINK.CONTACT:
                            return <Contact user={user} onClose={() => handleClick(LINK.HOME)}/>
                        case LINK.TERMS_CONDITIONS:
                            return <TermsConditions onClose={() => handleClick(LINK.HOME)}/>
                        case LINK.PRIVACY_POLICY:
                            return <PrivacyPolicy onClose={() => handleClick(LINK.HOME)}/>
                        default:
                            return null;
                    }
                })()}
            </Offcanvas.Body>
        </Offcanvas>
        <DeleteAccountModal
            user={user}
            show={showDeleteAccount}
            handleClose={() => setShowDeleteAccount(false)}
        />
    </>
}