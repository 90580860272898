import React from "react";
import {Avatar} from "./User/Avatar";

export function EventMessage({event, onClick}) {
    const messages = JSON.parse(JSON.stringify(event.messages));
    const lastMessage = messages.pop();

    return <div
        className="d-flex gap-3 msg-item"
        key={event.id}
        onClick={onClick}
    >
        <div className="img-lg img-outer overflow-visible position-relative">
            <img
                src={event.picture}
                className="rounded-circle"
                alt=""
            />
            <div className="inner-img">
                <Avatar user={lastMessage.author} size="small" />
            </div>
        </div>
        <div className="flex-grow-1 mt-2">
            <h6 className="d-flex justify-content-between">
                {event.label} <span className="time">{lastMessage.hour}</span>
            </h6>
            <p className="mb-0 d-flex justify-content-between msg-content text-gray">
                {lastMessage.content}
                <span className="msg-count">1</span>
            </p>
        </div>
    </div>
}