import Modal from "react-bootstrap/Modal";
import {Header} from "./Header";
import {Avatar} from "../User/Avatar";

export function PollResultModal({show, handleClose}) {
    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
        scrollable
        centered
    >
        <Header
            title="Poll Resutls"
            subtitle="3 votes"
            handleClose={handleClose}
        />
        <Modal.Body>
            <h5 className="fw-bold text-blue">Where we have to make a dinner?</h5>
            <p className="text-blue small mb-1 fw-bold">Chez Garpo - 30%</p>
            <div className="bg-light rounded-3 py-2 mb-3 result-listing">
                <div className="d-flex gap-2 align-items-center px-4">
                    <Avatar user={{name: 'Christoper'}} />
                    Christoper
                </div>
            </div>
            <p className="text-blue small mb-1 fw-bold ">Hippopotamus - 70%</p>
            <div className="bg-light rounded-3 py-2 mb-3 result-listing">
                <div className="d-flex gap-2 align-items-center px-4">
                    <Avatar user={{name: 'Christoper'}} />
                    Christoper
                </div>
                <hr/>
                <div className="d-flex gap-2 align-items-center px-4">
                    <Avatar user={{name: 'Christoper'}} />
                    Christoper
                </div>
            </div>
        </Modal.Body>
    </Modal>
}