import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class PollRepository extends AbstractRepository {
    put(userAnswerId, answerId, data) {
        return axios.put(`/user-answer/${userAnswerId}/answer/${answerId}`, data);
    }
    post(data) {
        return axios.post('poll', data);
    }
    getAll(eventId) {
        return axios.get(`/event/${eventId}/polls`);
    }
    delete(id) {
        return axios.delete(`/polls/${id}`);
    }
    vote(answerId) {
        return axios.put(`/answer/${answerId}/vote`, {})
    }
}

export default new PollRepository();