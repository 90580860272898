import axios from "axios";
import {AbstractRepository} from "./AbstractRepository";

class UserRepository extends AbstractRepository {
    me() {
        return axios.get('/user/me');
    }
    login(data) {
        return axios.post('/api/login', data);
    }
    post(data) {
        return axios.post('/user', data);
    }
    put(id, data) {
        return axios.put(`/user/${id}`, data);
    }

    delete(id) {
        return axios.delete(`/user/${id}`);
    }
}

export default new UserRepository();