import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Header} from "../Header";
import {useState} from "react";
import PollRepository from "../../../../repositories/PollRepository";
import {PollForm} from "./PollForm";
import {useTranslation} from "react-i18next";

export function CreatePollModal({show, handleClose, event, callback}) {
    const [poll, setPoll] = useState(null);
    const {t} = useTranslation();

    const handlerSubmit = async () => {
        poll.eventId = event.id
        poll.status = 1

        await PollRepository.post(poll)
        callback('poll')
        handleClose()
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
        scrollable
        centered
    >
        <Header
            title="modal.create_poll.title"
            handleClose={handleClose}
            handleSend={handlerSubmit}/>
        <Modal.Body>
            <div className="d-flex flex-column flex-column-reverse justify-content-between">
                <Tabs
                    defaultActiveKey="general"
                    className="mt-4 theme-tab tab-reverse"
                >
                    <Tab eventKey="general" title={t('modal.create_poll.type.general')}>
                        <PollForm callback={setPoll} questionType='general' />
                    </Tab>
                    <Tab eventKey="time" title={t('modal.create_poll.type.time')}>
                        <PollForm callback={setPoll} questionType='time' />
                    </Tab>
                    <Tab eventKey="location" title={t('modal.create_poll.type.location')}>
                        <PollForm callback={setPoll} questionType='location' />
                    </Tab>
                </Tabs>
            </div>
        </Modal.Body>
    </Modal>
}