import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Header} from "./Header";
import {useEffect, useState} from "react";
import ChecklistRepository from "../../../repositories/ChecklistRepository";
import {useTranslation} from "react-i18next";

export function CreateChecklistModal({show, handleClose, event, callback, checklist = null}) {
    const defaultItem = {content: ''}
    const [label, setLabel] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [items, setItems] = useState([
        defaultItem,
        defaultItem,
        defaultItem,
        defaultItem,
    ]);
    const {t} = useTranslation();

    useEffect(() => {
        if (checklist !== null) {
            setLabel(checklist.label);
            setCheckbox(checklist.checkbox);
            setItems(checklist.items)
        }
    }, []);

    function updateItem(e, key) {
        const oldItems = JSON.parse(JSON.stringify(items))
        oldItems[key].content = e.target.value;

        setItems(oldItems);
    }

    function addItem() {
        setItems([...items, defaultItem])
    }

    async function handlerSave() {
        try {
            const data = {
                label,
                checkbox,
                items
            };
            if (checklist !== null) {
                await ChecklistRepository.put(checklist.id, data)
            } else {
                await ChecklistRepository.post(event.id, data)
            }
            callback('checklist')
            handleClose()
        } catch (e) {
            console.log(e)
        }
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="theme-modal"
        scrollable
        centered
    >
        <Header
            title="modal.create_checklist.title"
            handleClose={handleClose}
            handleSend={handlerSave}/>
        <Modal.Body>
            <Form className="title-form">
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={t("modal.create_checklist.name")}
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                </Form.Group>
                <p className="mb-2">Add items</p>
                {items.map((item, index) => (
                    <Form.Group className="mb-3" key={index}>
                        <Form.Control
                            type="text"
                            placeholder={`${t("modal.create_checklist.item")} ${index + 1}`}
                            value={item.content}
                            onChange={(e) => updateItem(e, index)}
                        />
                    </Form.Group>
                ))}
                <p
                    onClick={addItem}
                    className="mb-2 text-end text-blue">
                    {t("modal.create_checklist.add_answer")}
                </p>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={t("modal.create_checklist.check")}
                    defaultChecked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                    className="bg-light d-flex flex-row-reverse form-check form-switch justify-content-between p-0 p-3 rounded-4"
                />
            </Form>
        </Modal.Body>
    </Modal>
}