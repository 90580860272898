import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import {FormEvent} from "../../components/FormEvent";
import {useEffect, useState} from "react";
import EventRepository from "../../../repositories/EventRepository";

function EventUpdate() {
    const {id} = useParams();
    const [event, setEvent] = useState();

    useEffect(() => {
        getEvent(id)
    }, [id]);

    const getEvent = async (id) => {
        const {data} = await EventRepository.get(id)
        setEvent(data)
    }

    return <PageLayout>
        <Container>
            <div className="box-top">
                <div className="box p-md-3">
                    <FormEvent event={event}/>
                </div>
            </div>
        </Container>
    </PageLayout>
}

export default EventUpdate;
