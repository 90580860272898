import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import MessageRepository from "../../../repositories/MessageRepository";

export function CreateChatModal({show, handleClose, event}) {
    const navigate = useNavigate();

    const handleCreateChat = async () => {
        try {
            const {data} = await MessageRepository.post(event.id, "Chat Created")

            navigate(`/chat/${data.id}`, { replace: true });
        } catch (err) {
            console.log(err);
        }
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        scrollable
        centered
    >
        <Modal.Body>
            <p className="text-center mt-3 mb-4">
                Do you want to create a chat for <strong>{event.label}</strong>?
            </p>
            <div className="row">
                <div className="col-md-6">
                    <Button
                        variant="primary"
                        className="text-white w-100 px-3"
                        onClick={handleCreateChat}
                    >
                        Create event chat
                    </Button>
                </div>
                <div className="col-md-6">
                    <Button
                        variant="outline-secondary"
                        className="m-lg-0 mt-2 w-100 px-3"
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}