import PageLayout from "../../layout/page-layout";
import {useNavigate} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

function Expenses() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/expenses-detail", {replace: true});
    };
    return <PageLayout>
        <Container>
            <div className="box p-md-3">
                <div className="row page-title">
                    <div className="col-md-6 d-flex align-items-center">
                        <h4 className="page-title fw-bold">Expenses</h4>
                    </div>
                    <div className="col-md-3 offset-md-2">
                        <Form className="title-form">
                            <Form.Group>
                                <div className="input-icon">
                                    <img
                                        src="/img/icon_search.svg"
                                        alt=""
                                        className="icon-left"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Pand Account"
                                        className="icon-single"
                                    />
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <hr/>
                <div className="row expense-listing">
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/event_img.png" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_plus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">Awp Concert</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/event4.jpg" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_minus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">
                                Bachelor's Party
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/event5.jpg" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_minus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">Concert</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/karachi.jpg" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_plus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">DJ Beruang</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/lahore.jpg" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_plus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">
                                Bachelor's Party
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="align-items-center d-flex gap-3"
                            onClick={handleClick}
                        >
                            <div className="expense-user">
                                <div className="img-outer img-lg">
                                    <img src="/img/event_thumb.jpg" alt=""/>
                                </div>
                                <img
                                    src="/img/icon_plus_circle.svg"
                                    className="end-0 position-absolute top-0"
                                    alt=""
                                />
                            </div>
                            <div className="flex-grow-1 expense-title">DJ Party</div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </PageLayout>
}

export default Expenses;
