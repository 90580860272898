import {NavLink, useNavigate} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import React, {useContext, useState} from "react";
import {DeleteEventModal} from "../Modal/DeleteEventModal";
import {UserContext} from "../../layout/context/UserProvider";

export function HeaderEvent({ event }) {
    const { user } = useContext(UserContext)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const isAdmin = user.id === event?.userCreator?.id

    const handleEditEvent = () => {
        navigate(`/event-update/${event.id}`, {replace: true});
    };

    return <>
        <div className="event-cover-img">
            <img
                className="img-fluid cover-img"
                alt=""
                src={event.picture}
            />
            <div className="controls">
                <NavLink to="/home" className="icon-transparent">
                    <img src="/img/icon_chevron_left.svg" alt=""/>
                </NavLink>
                <div className="icon-transparent ms-auto">
                    <img src="/img/icon_calendar_add.svg" alt=""/>
                </div>
                {isAdmin && <Dropdown className="theme-dropdwon">
                    <Dropdown.Toggle
                        className="icon-transparent dropdown"
                        as="div"
                        align="end"
                    >
                        <img src="/img/icon_ellipsis_dark.svg" alt=""/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={handleEditEvent}>
                            <img
                                src="/img/icon_edit.svg"
                                className="position-relative top-0"
                                alt=""
                            /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={() => setShow(true)}>
                            <img
                                src="/img/icon_trash_red.svg"
                                className="position-relative top-0"
                                alt=""
                            /> Delete event
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </div>
        <DeleteEventModal
            show={show}
            handleClose={() => setShow(false)}
            event={event}/>
    </>
}