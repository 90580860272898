import {Avatar} from "../../User/Avatar";

export function Carpoll() {
    return <div className="bg-light rounded-4 p-3 widget-outer">
        <div className="d-flex gap-4 align-items-baseline mb-1">
            <div className="d-flex gap-1 align-items-center ms-auto">
                <Avatar user={{name: 'Adam\'s Car'}} />
                <p className="mb-0 text-blue fw-bold">
                    Adam's Car
                </p>
            </div>
            <div className="d-flex gap-1">
                <img src="/img/icon_edit_black.svg" alt=""/>
                <img src="/img/icon_trash_red.svg" alt=""/>
            </div>
        </div>
        <div className="d-flex align-items-center gap-1 fw-bold f-12">
            <img src="/img/icon_send_black.svg" alt=""/>
            <span>Friday 27 December</span>
            <span className="bg-dark dot mx-1"></span>
            <span>10:00 PM</span>
        </div>
        <div className="d-flex gap-2 justify-content-center my-2 widget-outer">
            <Avatar user={{name: 'Toto'}} />
            <Avatar user={{name: 'Toto'}} />
            <Avatar user={{name: 'Toto'}} />
            <Avatar user={{name: 'Toto'}} />
        </div>
        <div className="d-flex align-items-center gap-1 fw-bold f-12">
            <img src="/img/icon_send_left.svg" alt=""/>
            <span>Friday 27 December</span>
            <span className="bg-dark dot mx-1"></span>
            <span>10:00 PM</span>
        </div>
        <div className="d-flex gap-2 justify-content-center my-2">
            <Avatar user={{name: 'Toto'}} />
            <Avatar user={{name: 'Toto'}} />
            <Avatar user={{name: 'Toto'}} />
            <div className="img-md img-outer border border-3 border-white p-1 bg-white">
                <img alt="" src="/img/icon_add_red.svg"/>
            </div>
        </div>
        <p className="text-center mb-0 f-12 mt-3 fw-bold mx-auto mxw-170">
            <img
                src="/img/icon_location_dark.svg"
                alt=""
                height={17}
            />{" "}
            10 Rue de Montreuil, 75011 Paris, France
        </p>
    </div>
}