import Form from "react-bootstrap/Form";
import {Header} from "./Header";
import {Avatar} from "../../User/Avatar";
import React, {useContext, useEffect, useState} from "react";
import UserRepository from "../../../../repositories/UserRepository";
import {UserContext} from "../../../layout/context/UserProvider";
import UploadFileRepository from "../../../../repositories/UploadFileRepository";
import {toastMessage} from "../../../helpers/toast";
import {useTranslation} from "react-i18next";
import {Button} from "../../Form/Button";

export function Edit({user, onClose}) {
    const {t} = useTranslation();
    const {setUser} = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(false)
    const [picture, setPicture] = useState('')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')

    const save = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const {data} = await UserRepository.put(user.id, {
                name,
                phoneNumber,
                password,
                picture
            })

            setUser(data)
        } finally {
            setIsLoading(false)
        }
    }

    const uploadFile = async (e) => {
        const file = e.target.files[0]
        const { data, error } = await UploadFileRepository.upload(file, 'avatar');

        if (error) {
            toastMessage(error.message, 'error')
        } else {
            setPicture(data.fullPath)
        }
    }

    useEffect(() => {
        console.log(user)

        setName(user.name)
        setPhoneNumber(user.phoneNumber)
        setPicture(user.picture)
    }, user)

    return <div className="user-profile my-4 pt-3">
        <Header onClose={onClose}/>
        <div className="mt-0 profile-img upload-img">
            <Avatar user={{name, picture}} size='big'/>
            <Form.Group
                controlId="formFile"
                className="position-absolute end-0 bottom-0"
            >
                <Form.Label>
                    <img src="/img/icon_camera.svg" alt="" className="cursor-pointer"/>
                </Form.Label>
                <Form.Control type="file" className="d-none" onChange={uploadFile}/>
            </Form.Group>
        </div>
        <Form className="text-start" onSubmit={save}>
            <Form.Group className="mb-4" controlId="fullName">
                <Form.Label className="fw-bold">{t('form.name.label')}</Form.Label>
                <div className="input-icon">
                    <img
                        src="/img/icon_user.svg"
                        alt=""
                        className="icon-left"
                    />
                    <Form.Control
                        value={name}
                        onChange={e => setName(e.target.value)}
                        type="text"
                        placeholder={t('form.name.placeholder')}
                        className="icon-single"
                    />
                </div>
            </Form.Group>
            {/*<Form.Group className="mb-4" controlId="fullName">*/}
            {/*  <Form.Label className="fw-bold">Email</Form.Label>*/}
            {/*  <div className="input-icon">*/}
            {/*    <img*/}
            {/*      src="/img/icon_envelope_gray.svg"*/}
            {/*      alt=""*/}
            {/*      className="icon-left"*/}
            {/*    />*/}
            {/*    <Form.Control*/}
            {/*      type="text"*/}
            {/*      placeholder="Input Email"*/}
            {/*      className="icon-single"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Form.Group>*/}
            <Form.Group className="mb-4" controlId="email">
                <Form.Label className="fw-bold">
                    {t('form.phone_number.label')}
                </Form.Label>
                <div className="input-icon">
                    <img
                        src="/img/icon_phone_gray.svg"
                        alt=""
                        className="icon-left"
                    />
                    <Form.Control
                        type="text"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        placeholder={t('form.phone_number.placeholder')}
                        className="icon-single"
                        disabled
                        readOnly
                    />
                </div>
            </Form.Group>
            <Form.Group className="mb-4" controlId="password">
                <Form.Label className="fw-bold">{t('form.password.label')}</Form.Label>
                <div className="input-icon">
                    <img
                        src="/img/icon_password.svg"
                        alt=""
                        className="icon-left"
                    />
                    <Form.Control
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        placeholder={t('form.password.placeholder')}
                        className="icon-single"
                    />
                    <img
                        src="/img/icon_eye.svg"
                        alt=""
                        className="icon-right"
                    />
                </div>
            </Form.Group>
            <div className="text-center">
                <Button
                    title="edit_user.submit"
                    isLoading={isLoading}/>
            </div>
        </Form>
    </div>
}