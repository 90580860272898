export function Avatar({user, size = "normal"}) {
    return <>
        {user && user.picture ?
            <div className={`avatar ${size && size}`}>
                <img src={user.picture} alt=""/>
            </div> :
            <span className={`user-initials green ${size && size}`}>
                {user?.name.charAt(0).toUpperCase()}
            </span>
        }
    </>
}