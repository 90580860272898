import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UserRepository from "../../../repositories/UserRepository";
import {logout} from "../../helpers/auth";
import {useTranslation} from "react-i18next";

export function DeleteAccountModal({user, show, handleClose}) {
    const {t} = useTranslation();
    const handleDeleteAccount = () => {
        UserRepository.delete(user.id)
        logout()
    }

    return <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-alert"
        scrollable
        centered
    >
        <Modal.Body>
            <p className="text-center mt-3 mb-4">
                {t('modal.delete_account.title')}
            </p>
            <div className="row">
                <div className="col-md-6">
                    <Button
                        variant="primary"
                        className="text-white w-100 px-3"
                        onClick={handleClose}
                    >{t('modal.delete_account.cancel')}</Button>
                </div>
                <div className="col-md-6">
                    <Button
                        variant="outline-secondary"
                        className="m-lg-0 mt-2 w-100 px-3"
                        onClick={handleDeleteAccount}
                    >{t('modal.delete_account.submit')}</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}