import AuthLayout from "../../layout/auth-layout";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Header} from "../../components/Login/Header";
import * as React from "react";
import {Button} from "../../components/Form/Button";
import {useNavigate, useParams} from "react-router-dom";
import TokenRepository from "../../../repositories/TokenRepository";
import {toastMessage} from "../../helpers/toast";

function ResetPassword() {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        validateToken(token)
    }, [token]);


    const validateToken = async (token) => {
        try {
            await TokenRepository.validate(token);
        } catch (e) {
            navigate("/", {replace: true});
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            await TokenRepository.put(token, {
                password,
                confirmPassword
            });
            toastMessage(t('reset_password.message'))
            navigate('/signin', {replace: true});
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    return <AuthLayout>
        <Header title="reset_password.title" />
        <p className="text-gray mt-5 mb-4">{t('reset_password.sub_title')}</p>
        <Form className="text-start" onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="password">
                <Form.Label className="fw-bold">{t('form.password.label')}</Form.Label>
                <div className="input-icon">
                    <img src="/img/icon_password.svg" alt="" className="icon-left"/>
                    <Form.Control
                        type="password"
                        placeholder={t('form.password.placeholder')}
                        className="icon-single"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <img src="/img/icon_eye.svg" alt="" className="icon-right"/>
                </div>
            </Form.Group>
            <Form.Group className="mb-4" controlId="confirmPassword">
                <Form.Label className="fw-bold">{t('form.confirm_password.label')}</Form.Label>
                <div className="input-icon">
                    <img src="/img/icon_password.svg" alt="" className="icon-left"/>
                    <Form.Control
                        type="password"
                        placeholder={t('form.confirm_password.placeholder')}
                        className="icon-single"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <img src="/img/icon_eye.svg" alt="" className="icon-right"/>
                </div>
            </Form.Group>
            <Button
                title="reset_password.submit"
                isLoading={isLoading} />
        </Form>
    </AuthLayout>
}

export default ResetPassword;
