import {useEffect, useState} from "react";
import AuthLayout from "../../layout/auth-layout";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import UserRepository from "../../../repositories/UserRepository";
import UserEventRepository from "../../../repositories/UserEventRepository";
import EventRepository from "../../../repositories/EventRepository";
import {Header} from "../../components/Login/Header";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Alternative} from "../../components/Login/Alternative";

function JoinWithoutAccount() {
    let {id} = useParams();
    const [setModal, setModalState] = useState("step1");
    const [event, setEvent] = useState([]);
    const [eventCode, setEventCode] = useState("");
    const [userName, setUserName] = useState("");
    const {t} = useTranslation();

    const handleChange = (event) => {
        setUserName(event.target.value);
    };

    const handleClick = async (modalState) => {
        if (modalState === "step2") {
            try {
                const {data} = await EventRepository.getCode(eventCode)
                setEvent(data);

                setModalState(modalState);
            } catch (error) {
                console.log(error.response.data.message);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if (modalState === "step4") {
            try {
                const {data} = await UserRepository.post({
                    name: userName,
                    type: "guest",
                })

                try {
                    await UserEventRepository.post({
                        event: event.id,
                        author: data.id,
                        role: "member",
                        status: 2,
                    })
                    setModalState(modalState);
                } catch (error) {
                    console.log(error.response.data.message);
                }
            } catch (error) {
                console.log(error.response.data.message);
            }
        } else {
            setModalState(modalState);
        }
    };

    return <AuthLayout>
        <Header title="guest.title"/>
        <NavLink to="/">
            <img src="/img/logo.svg" alt="" className="d-md-none mb-2 mt-3"/>
        </NavLink>
        {(() => {
            switch (setModal) {
                case "step1":
                    return (
                        <>
                            <div className="align-items-center d-flex gap-2 my-4">
                                <div className="img-outer">
                                    <img src="/img/event_img.png" alt=""/>
                                </div>
                                <p className="mb-0">{id}</p>
                            </div>
                            <Form className="text-start">
                                <Form.Group className="mb-4" controlId="eventURL">
                                    <Form.Label className="fw-bold">{t('guest.form.event_link.label')}</Form.Label>
                                    <div className="input-icon">
                                        <img
                                            src="/img/icon_link.svg"
                                            alt=""
                                            className="icon-left"
                                        />
                                        <Form.Control
                                            type="text"
                                            value={eventCode}
                                            placeholder={t('guest.form.event_link.placeholder')}
                                            onChange={(e) => setEventCode(e.target.value)}
                                            className="icon-single"
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                            <Button
                                variant="primary"
                                className="text-white w-100 mb-5"
                                onClick={() => handleClick("step2")}
                            >
                                {t('guest.button.step1')}
                            </Button>
                        </>
                    );
                case "step2":
                    return (
                        <>
                            <div className="align-items-center d-flex gap-2 my-4">
                                <div className="img-outer">
                                    <img src="/img/event_img.png" alt=""/>
                                </div>
                                <p className="mb-0">{event.code}</p>
                            </div>
                            <Form className="text-start">
                                <Form.Group className="mb-4" controlId="userName">
                                    <Form.Label className="fw-bold">{t('guest.find_name')}</Form.Label>
                                    <div className="input-icon">
                                        <img
                                            src="/img/icon_user.svg"
                                            alt=""
                                            className="icon-left"
                                        />
                                        <Form.Select className="icon-single">
                                            {event.userEvents.map((userEvent) => (
                                                <>
                                                    {userEvent.author.type === "guest" && (
                                                        <option>{userEvent.author.name}</option>
                                                    )}
                                                </>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    className="text-white w-100 mb-2"
                                    as={NavLink}
                                    to={`/event-detail/${event.id}`}
                                >
                                    {t('guest.join')}
                                </Button>
                                <Button
                                    variant="outline-primary mb-4"
                                    className="w-100"
                                    onClick={() => handleClick("step3")}
                                >
                                    {t('guest.button.step2')}
                                </Button>
                            </Form>
                        </>
                    );
                case "step3":
                    return (
                        <>
                            <div className="align-items-center d-flex gap-2 my-4">
                                <div className="img-outer">
                                    <img src="/img/event_img.png" alt=""/>
                                </div>
                                <p className="mb-0">{event.code}</p>
                            </div>
                            <Form className="text-start">
                                <Form.Group className="mb-4" controlId="name">
                                    <Form.Label className="fw-bold">{t('guest.form.name.label')}</Form.Label>
                                    <div className="input-icon">
                                        <img
                                            src="/img/icon_user.svg"
                                            alt=""
                                            className="icon-left"
                                        />
                                        <Form.Control
                                            type="text"
                                            placeholder={t('guest.form.name.placeholder')}
                                            className="icon-single"
                                            value={userName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    className="text-white w-100 mb-4"
                                    onClick={() => handleClick("step4")}
                                >
                                    {t('guest.button.step3')}
                                </Button>
                            </Form>
                        </>
                    );
                case "step4":
                    return (
                        <>
                            <p className="text-gray">{t('guest.step4.title', {code: event.code})}</p>
                            <p className="text-gray">{t('guest.step4.sub_title')}</p>

                            <div className="event-success mb-4 position-relative text-center">
                                <img
                                    src="/img/success_event_infographic.svg"
                                    alt=""
                                    className="img-fluid"
                                ></img>
                                <div className="align-items-center d-flex gap-2 position-absolute event-user">
                                    <div className="img-outer">
                                        <img src="/img/event_img.png" alt=""/>
                                    </div>
                                    <p className="mb-0">{event.code}</p>
                                </div>
                            </div>
                        </>
                    );
                default:
                    return null;
            }
        })()}
        <Button
            as={NavLink}
            variant="primary"
            type="submit"
            className="text-white w-100"
            to="/signup"
        >
            {t('guest.register')}
        </Button>
        <Alternative/>
        <p className="text-gray mt-4 mb-0 text-center">
            {t('onboarding.has_account')} <NavLink to="/signin" className="fw-bold">{t('sign_in.title')}</NavLink>
        </p>
    </AuthLayout>
}

export default JoinWithoutAccount;
