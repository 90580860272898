import {NavLink, useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth-layout";
import Form from "react-bootstrap/Form";
import {login} from "../../helpers/auth";
import * as React from "react";
import UserRepository from "../../../repositories/UserRepository";
import {useTranslation} from "react-i18next";
import {Alternative} from "../../components/Login/Alternative";
import {Header} from "../../components/Login/Header";
import {Button} from "../../components/Form/Button";

function SignUp() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        name: "",
        phoneNumber: "",
        password: "",
    });
    const {t} = useTranslation();
    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };
    const loginPayload = {
        phoneNumber: form.phoneNumber,
        password: form.password,
    };
    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        try {
            const {data} = await UserRepository.post(loginPayload)
            login(data.token);
            navigate("/home", {replace: true});
        } catch (error) {
            console.log(error)
        }
    };
    return <AuthLayout>
        <Header title="sign_up.title" />
        <NavLink to="/">
            <img src="/img/logo.svg" alt="" className="d-md-none mb-3 mt-2"/>
        </NavLink>
        <p className="text-gray mb-4">{t('sign_up.sub_title')}</p>
        <Form className="text-start" onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="name">
                <Form.Label className="fw-bold">{t('form.name.label')}</Form.Label>
                <div className="input-icon">
                    <img src="/img/icon_user.svg" alt="" className="icon-left"/>
                    <Form.Control
                        type="text"
                        value={form.name}
                        onChange={handleChange}
                        placeholder={t('form.name.placeholder')}
                        className="icon-single"
                    />
                </div>
            </Form.Group>
            <div className="d-flex gap-3">
                {/*<Form.Group className="mb-4">*/}
                {/*    <Form.Label className="fw-bold">Prefix</Form.Label>*/}
                {/*    <Form.Select className="w-100px">*/}
                {/*        <option>+33</option>*/}
                {/*        <option>+01</option>*/}
                {/*        <option>+32</option>*/}
                {/*        <option>+01</option>*/}
                {/*        <option>+01</option>*/}
                {/*    </Form.Select>*/}
                {/*</Form.Group>*/}
                <Form.Group className="flex-grow-1 mb-4" controlId="phoneNumber">
                    <Form.Label className="fw-bold">{t('form.phone.label')}</Form.Label>
                    <div className="input-icon">
                        <img
                            src="/img/icon_phone_gray.svg"
                            alt=""
                            className="icon-left"
                        />
                        <Form.Control
                            type="text"
                            placeholder={t('form.phone.placeholder')}
                            className="icon-single"
                            value={form.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                </Form.Group>
            </div>
            <Form.Group className="mb-4" controlId="password">
                <Form.Label className="fw-bold">{t('form.password.label')}</Form.Label>
                <div className="input-icon">
                    <img src="/img/icon_password.svg" alt="" className="icon-left"/>
                    <Form.Control
                        type="password"
                        placeholder={t('form.phone.placeholder')}
                        className="icon-duo"
                        value={form.password}
                        onChange={handleChange}
                    />
                    <img src="/img/icon_eye.svg" alt="" className="icon-right"/>
                </div>
            </Form.Group>
            <Button
                title="sign_up.submit"
                isLoading={isLoading} />
        </Form>
        <Alternative/>
        <p className="text-gray mt-4 mb-0 text-center">
            {t('onboarding.has_account')} <NavLink to="/signin" className="fw-bold">
            {t('sign_in.title')}
        </NavLink>
        </p>
    </AuthLayout>
}

export default SignUp;
