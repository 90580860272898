import PageLayout from "../../layout/page-layout";
import Container from "react-bootstrap/Container";
import {useParams} from "react-router-dom";
import {Chat as ChatComponent} from "../../components/Chat/Chat";

function Chat() {
    const {id} = useParams();
    document.body.classList.add("open-msg-box");

    return <PageLayout>
        <Container>
            <ChatComponent
                eventId={id}
            />
        </Container>
    </PageLayout>
}

export default Chat;
