import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const copyEventCode = () => {
    const copyText = document.getElementById("event-code");
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);
}

const canShareEvent = !!navigator.share

const shareEvent = async () => {
    const copyText = document.getElementById("event-code");
    const data = {
        title: "Pandout",
        text: "Invite friends",
        url: copyText.value,
    }
    await navigator.share(data);
}

export function ShareEvent({event}) {
    return <>
        <div className="mb-3">
            <div className="input-icon right-only">
                <Form.Control
                    id="event-code"
                    type="text"
                    placeholder="pandout.com/48EjdL32D"
                    className="icon-duo bg-light"
                    defaultValue={`https://pandout.com/e/${event.code}`}
                />
                <img
                    src="/img/icon_copy.svg"
                    alt=""
                    className="icon-right cursor-pointer"
                    onClick={copyEventCode}
                />
            </div>
        </div>
        {canShareEvent && <Button
            variant="outline-primary"
            className="btn-icon d-flex m-auto px-3"
            onClick={shareEvent}
        >
            Share the link
            <img src="/img/icon_send_2.svg" alt=""/>
        </Button>}
    </>
}